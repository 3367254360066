import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Table, Typography, Space } from "antd";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import { getTemplates } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setTemplatesAction } from "../../redux/reducers/adminReducer";
import { IoRefreshOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
function Templates() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { templates } = useSelector((state) => state.admin);
  const [loading, setLoading] = useState(false);
  const [formattedTemplates, setFormattedTemplates] = useState([]);

  useEffect(() => {
    if (templates?.length < 1) {
      getAdminData();
    } else {
      formatTemplates(templates);
    }
  }, []);
  const getAdminData = async () => {
    try {
      setLoading(true);
      const res = await getTemplates();
      dispatch(setTemplatesAction(res.data.templates));
      await new Promise((resolve) => setTimeout(resolve, 800));
      formatTemplates(res.data.templates);
      openNotification({
        title: "Success",
        description: "Fetched Successfully",
        type: "success",
        rtl: true,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFormattedTemplates([]);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const formatTemplates = (tmpStores) => {
    let allStores = [];
    if (tmpStores) {
      allStores = tmpStores;
    } else {
      allStores = templates;
    }
    if (allStores.length === 0) {
      setFormattedTemplates([]);
      setLoading(false);
      return;
    }
    const formatted = allStores.map((template) => {
      return {
        key: template._id,
        ...template,
      };
    });
    setFormattedTemplates(formatted);
    setLoading(false);
  };

  const column = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record) => <Text>{dayjs(text).format("DD-MM-YYYY")}</Text>,
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      key: "action",
      render: (text, record) => {
        return (
          <CustomButton
            text={intl.formatMessage({ id: "edit" })}
            onClick={() => {
              navigate(`/templates-admin/${record._id}`);
            }}
            type='link'
          />
        );
      },
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Space direction='horizontal' space={10}>
            <Title
              level={4}
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              {intl.formatMessage({ id: "templates" })}
            </Title>
            <CustomButton
              text={intl.formatMessage({ id: "refresh" })}
              type='text'
              icon={<IoRefreshOutline />}
              onClick={getAdminData}
            />
          </Space>
          <CustomButton
            text={intl.formatMessage({ id: "addTemplate" })}
            onClick={() => {
              navigate("/new-template-admin");
            }}
            type='primary'
          />
        </div>
        <div>
          <Table
            columns={column}
            dataSource={formattedTemplates}
            pagination={{ pageSizeOptions: [10, 50, 100, 500, 1000] }}
            className='light-shadow'
            // make the row clickable
            rowClassName={"pointer"}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate(`/templates-admin/${record._id}`);
                },
              };
            }}
            style={{
              backgroundColor: COLORS.white,
              padding: "5px 5px",
              borderRadius: 10,
            }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default Templates;
