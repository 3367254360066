import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { getStoreCustomers, getStoreGroups } from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Table, Typography } from "antd";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { useNavigate } from "react-router-dom";
import {
  setStoreCustomersAction,
  setStoreGroupsAction,
} from "../../redux/reducers/storeReducer";

const { Title, Text } = Typography;

function Customers() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { store, groups } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(50);
  const [customers, setCustomers] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (store?._id) {
      getAllCustomers();
      getAllGroups();
    }
  }, [store?._id]);

  const getAllGroups = async () => {
    try {
      const res = await getStoreGroups();
      if (res?.data?.groups) {
        dispatch(setStoreGroupsAction(res?.data?.groups));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCustomers = async (p, l) => {
    try {
      setLoading(true);
      let pg = p || page;
      let lg = l || length;
      const res = await getStoreCustomers(pg, lg);
      let allCity = [];
      if (res?.data?.customers) {
        setCustomers(res?.data?.customers);
        dispatch(setStoreCustomersAction(res?.data?.customers));
        setTotal(res?.data?.total);
        res?.data?.customers.forEach((c) => {
          if (c?.city) {
            if (!cities.includes(c?.city)) {
              allCity.push(c?.city);
            }
          }
        });
        setCities(allCity);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      key: "email",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    // gender
    {
      title: intl.formatMessage({ id: "gender" }),
      dataIndex: "gender",
      key: "gender",
      filters: [
        {
          text: intl.formatMessage({ id: "male" }),
          value: "male",
        },
        {
          text: intl.formatMessage({ id: "female" }),
          value: "female",
        },
        {
          text: intl.formatMessage({ id: "blank" }),
          value: "",
        },
      ],
      onFilter: (value, record) => {
        if (value === "") {
          return record?.gender !== "male" && record?.gender !== "female";
        }
        return record?.gender === value;
      },
    },
    // city
    {
      title: intl.formatMessage({ id: "city" }),
      dataIndex: "city",
      key: "city",
      filters: cities.map((c) => {
        return {
          text: c,
          value: c,
        };
      }),
      onFilter: (value, record) => record?.city === value,
    },
    {
      title: intl.formatMessage({ id: "customerPage" }),
      key: "customerUrl",
      dataIndex: "customerUrl",
      render: (text, record) => (
        <a href={text} target='_blank'>
          {intl.formatMessage({ id: "view" })}
        </a>
      ),
    },
    {
      title: intl.formatMessage({ id: "group" }),
      key: "otherGroupIds",
      dataIndex: "otherGroupIds",
      render: (groupsArray, record) => {
        return (
          <div>
            {groupsArray?.map((g, i) => {
              const found = groups?.find((gr) => `${gr?.groupId}` === `${g}`);
              return (
                <Text key={`${g}`} style={{ display: "block" }}>
                  {found?.name}
                </Text>
              );
            })}
          </div>
        );
      },
      filters: groups?.map((g) => {
        return {
          text: g?.name,
          value: g?.groupId,
        };
      }),
      onFilter: (value, record) => {
        return record?.otherGroupIds?.includes(value);
      },
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "customers" })}</Title>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Table
            dataSource={customers?.map((t) => {
              return {
                ...t,
                key: t?._id,
              };
            })}
            columns={columns}
            pagination={{
              total,
              pageSize: length,
              onChange: (page, length) => {
                setPage(page);
                setLength(length);
                getAllCustomers(page, length);
              },
            }}
            scroll={{ x: 800 }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default Customers;
