import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import openNotification from "../../Components/Notifications";
import { getStoreAdmin, updateStoreAdmin } from "../../API/fetch";
import COLORS from "../../Style/colors";
import {
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { checkIfValidPhone } from "../../helpers";
import { useSelector } from "react-redux";
import phoneCodes from "../Auth/PhoneCods.json";
import CustomButton from "../../Components/CustomButton";

const PLANS = {
  FREE: "free",
  BASIC: "basic",
  PREMIUM: "premium",
  ENTERPRISE: "enterprise",
};
const { Title, Text } = Typography;
const { Option } = Select;
function OneStoreAdmin() {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState(null);
  useEffect(() => {
    if (id) {
      getStoreData(id);
    } else {
      navigate("/stores");
    }
  }, []);
  const getStoreData = async (id) => {
    try {
      setLoading(true);
      const res = await getStoreAdmin(id);
      setStore(res.data.store);
      setLoading(false);
      openNotification({
        title: "Success",
        description: "Fetched Successfully",
        type: "success",
        rtl: true,
      });
      form.setFieldsValue({
        phone: res.data.store?.user?.phone?.slice(3),
        prefix: phoneCodes[0].dial_code,
        package: res.data.store?.subscriptions[0]?.plan,
        endDate: dayjs(res.data.store?.subscriptions[0]?.endDate),
        monthlyLimitEmails: res.data.store?.monthlyLimitEmails,
        // oneCampaignEmailsLimit: res.data.store?.oneCampaignEmailsLimit,
        // monthlyCampaignLimit: res.data.store?.monthlyCampaignLimit,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        user: {
          phone: `${values.prefix.replace("+", "")}${values.phone}`,
        },
        store: {
          monthlyLimitEmails: values.monthlyLimitEmails,
          // oneCampaignEmailsLimit: values.oneCampaignEmailsLimit,
          // monthlyCampaignLimit: values.monthlyCampaignLimit,
        },
        subscriptions: {
          plan: values.package,
          endDate: values.endDate.toDate(),
        },
      };

      if (values?.newPassword) {
        data.user.newPassword = values.newPassword;
      }

      const res = await updateStoreAdmin(id, data);
      setStore(res.data.store);
      openNotification({
        title: intl.formatMessage({ id: "success" }),
        description: intl.formatMessage({ id: "successUpdate" }),
        type: "success",
        rtl,
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  // console.log(store);

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={{
          width: 150,
        }}
      >
        {phoneCodes.map((info) => {
          return (
            <Option
              key={`${info.dial_code}-${info.name}`}
              value={info.dial_code}
            >
              {info.dial_code} {info.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "info" })}</Title>
      </div>
      <Spin spinning={loading} size='large'>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Descriptions
            title={intl.formatMessage({ id: "info" })}
            bordered
            className='my-font'
          >
            <Descriptions.Item
              label={intl.formatMessage({ id: "justName" })}
              span={2}
            >
              {store?.merchantName}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "store" })}
              span={2}
            >
              {store?.storeName}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "email" })}
              span={2}
            >
              {store?.user?.email}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "phone" })}
              span={2}
            >
              {store?.user?.phone}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "package" })}
              span={2}
            >
              {store?.subscriptions[0]?.plan}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "startDate" })}
              span={2}
            >
              {dayjs(store?.subscriptions[0]?.startDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "endDate" })}
              span={2}
            >
              {dayjs(store?.subscriptions[0]?.endDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "status" })}
              span={2}
            >
              <Tag
                color={
                  store?.subscriptions[0]?.status === "active" ? "green" : "red"
                }
              >
                {intl.formatMessage({
                  id: store?.subscriptions[0]?.status
                    ? store?.subscriptions[0]?.status
                    : "n/a",
                })}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "monthlyLimitEmails" })}
              span={2}
            >
              {store?.monthlyLimitEmails}
            </Descriptions.Item>
            {/* <Descriptions.Item
              label={intl.formatMessage({ id: "oneCampaignEmailsLimit" })}
              span={2}
            >
              {store?.oneCampaignEmailsLimit}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "monthlyCampaignLimit" })}
              span={2}
            >
              {store?.monthlyCampaignLimit}
            </Descriptions.Item> */}
            <Descriptions.Item
              label={intl.formatMessage({ id: "monthlyLimitEvents" })}
              span={2}
            >
              {store?.monthlyLimitEvents}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "usedEmails" })}
              span={2}
            >
              <Tag color='red'>
                {" "}
                {store?.monthlyLimitEmails - store?.remainingEmails}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "remainingEmails" })}
              span={2}
            >
              <Tag color='success'> {store?.remainingEmails}</Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "products" })}
              span={2}
            >
              {store?.products?.length}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "customers" })}
              span={2}
            >
              {store?.customers?.length}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "events" })}
              span={2}
            >
              {store?.tracks?.length}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "domain" })}
              span={2}
            >
              {store?.domain}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Divider />
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Form
            name='edit-store'
            layout='vertical'
            className='form-container'
            form={form}
            onFinish={onFinish}
            style={{
              marginRight: 10,
              marginLeft: 10,
            }}
            initialValues={{
              phone: store?.user?.phone?.slice(3),
              prefix: phoneCodes[0].dial_code,
              package: store?.subscriptions[0]?.plan,
              endDate: dayjs(store?.subscriptions[0]?.endDate),
              monthlyLimitEmails: store?.monthlyLimitEmails,
              // oneCampaignEmailsLimit: store?.oneCampaignEmailsLimit,
              // monthlyCampaignLimit: store?.monthlyCampaignLimit,
              newPassword: "",
            }}
          >
            <Row gutter={16}>
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  name='phone'
                  label={intl.formatMessage({ id: "phone" })}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "errorphone" }),
                    },
                    {
                      validator: (_, value) => {
                        const prefix = form.getFieldValue("prefix");
                        if (!prefix) {
                          return Promise.reject(
                            intl.formatMessage({ id: "errorphone" })
                          );
                        }
                        let valid = checkIfValidPhone(`${prefix}${value}`);
                        if (valid) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            intl.formatMessage({ id: "errorphone" })
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    addonBefore={rtl ? null : prefixSelector}
                    addonAfter={rtl ? prefixSelector : null}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  label={intl.formatMessage({ id: "package" })}
                  name='package'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <Select className='my-font'>
                    {Object.values(PLANS).map((plan) => (
                      <Select.Option key={plan} value={plan}>
                        {intl.formatMessage({ id: plan })}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  label={intl.formatMessage({ id: "endDate" })}
                  name='endDate'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <DatePicker className='my-font' />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  label={intl.formatMessage({ id: "monthlyLimitEmails" })}
                  name='monthlyLimitEmails'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                    min={0}
                    className='my-font'
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={16}>
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  label={intl.formatMessage({ id: "oneCampaignEmailsLimit" })}
                  name='oneCampaignEmailsLimit'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                    min={0}
                    className='my-font'
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  label={intl.formatMessage({ id: "monthlyCampaignLimit" })}
                  name='monthlyCampaignLimit'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                    min={0}
                    className='my-font'
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={16}>
              <Col sm={24} md={12} lg={12}>
                <Form.Item
                  label={intl.formatMessage({ id: "newPassword" })}
                  name='newPassword'
                >
                  <Input.Password
                    style={{
                      width: "100%",
                    }}
                    className='my-font'
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={12}></Col>
            </Row>
            <Row justify='center' align='middle' className='mt-3'>
              <Col span={24}>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    type='primary'
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
}

export default OneStoreAdmin;
