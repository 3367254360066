import React, { useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import { checkIfValidPhone } from "../../helpers";
import { editPassword } from "../../API/fetch";
import openNotification from "../../Components/Notifications";

const { Title, Text } = Typography;
function Account() {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const { store } = useSelector((state) => state.store);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const res = await editPassword(data);
      console.log(res?.data);
      if (res.status === 200) {
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "successUpdate" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        setShowEditPassword(false);
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: "something went wrong",
          type: "error",
          rtl,
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      let message = "something went wrong";
      if (err?.response?.data?.message) {
        message = intl.formatMessage({ id: err.response.data.message });
      }
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: message,
        type: "error",
        rtl,
      });
    }
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "account" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className='light-shadow'
      >
        <Descriptions
          title={intl.formatMessage({ id: "account" })}
          bordered
          className='my-font'
        >
          <Descriptions.Item
            label={intl.formatMessage({ id: "justName" })}
            span={2}
          >
            {store?.merchantName}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "store" })}
            span={2}
          >
            {store?.storeName}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "email" })}
            span={2}
          >
            {user?.email}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "phone" })}
            span={2}
          >
            {user?.phone}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "package" })}
            span={2}
          >
            {store?.subscriptions[0]?.plan}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "monthlyLimitEmails" })}
            span={2}
          >
            {store?.monthlyLimitEmails}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "usedEmails" })}
            span={2}
          >
            <Tag color='red'>
              {store?.monthlyLimitEmails - store?.remainingEmails}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "remainingEmails" })}
            span={2}
          >
            <Tag color='success'>{store?.remainingEmails}</Tag>
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "startDate" })}
            span={2}
          >
            {dayjs(store?.subscriptions[0]?.startDate).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "endDate" })}
            span={2}
          >
            {dayjs(store?.subscriptions[0]?.endDate).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: "status" })}
            span={2}
          >
            <Tag
              color={
                store?.subscriptions[0]?.status === "active" ? "green" : "red"
              }
            >
              {intl.formatMessage({
                id: store?.subscriptions[0]?.status
                  ? store?.subscriptions[0]?.status
                  : "n/a",
              })}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
        {/* form to change the password or the name or the phon for the user; */}

        <CustomButton
          onClick={() => setShowEditPassword(!showEditPassword)}
          text={intl.formatMessage({ id: "editPassword" })}
          type='primary'
          className='btn-text mt-5'
        />
        <Divider />
        {showEditPassword ? (
          <Spin spinning={loading}>
            <Form
              name='edit-account'
              layout='vertical'
              className='form-container'
              onFinish={onFinish}
              style={{
                marginRight: 10,
                marginLeft: 10,
              }}
              initialValues={{
                // name: user?.name,
                // phone: user?.phone?.replace("966", ""),
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
            >
              {/* <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                name='name'
                label={intl.formatMessage({ id: "justName" })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "nameRequired" }),
                  },
                ]}
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                name='phone'
                label={intl.formatMessage({ id: "phone" })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorphone" }),
                  },
                  {
                    validator: (_, value) => {
                      const prefix = "966";
                      let valid = checkIfValidPhone(`${prefix}${value}`);
                      if (valid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          intl.formatMessage({ id: "errorphone" })
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  addonBefore={rtl ? "" : "+966"}
                  addonAfter={rtl ? "+966" : ""}
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row> */}
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name='oldPassword'
                    label={intl.formatMessage({ id: "oldPassword" })}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "required",
                        }),
                      },
                    ]}
                  >
                    <Input.Password maxLength={100} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}></Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name='newPassword'
                    label={intl.formatMessage({ id: "newPassword" })}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "required",
                        }),
                      },
                    ]}
                  >
                    <Input.Password maxLength={100} minLength={6} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name='confirmPassword'
                    label={intl.formatMessage({ id: "confirmPassword" })}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "required",
                        }),
                      },
                      // check if it matches the new password
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            intl.formatMessage({
                              id: "newPassAndConfirmPassNotMatch",
                            })
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password maxLength={100} minLength={6} />
                  </Form.Item>
                </Col>
              </Row>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "save" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                type='primary'
              />
            </Form>
          </Spin>
        ) : null}
      </div>
    </div>
  );
}

export default Account;
