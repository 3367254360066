import { Col, Divider, Form, Input, Modal, Row } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../Components/CustomButton";

function TagModal({
  show,
  setShow,
  templateValue,
  setTemplateValue,
  updateDom,
}) {
  const [form] = Form.useForm();
  const intl = useIntl();

  return (
    <Modal
      title='Select Tags'
      open={show}
      onOk={() => setShow(false)}
      onCancel={() => {
        console.log("cancel");
        updateDom();
        setShow(false);
      }}
      width={800}
      footer={null}
    >
      <Form
        form={form}
        layout='horizontal'
        onFinish={(values) => {
          // format it as object;
          const { name, value } = values;
          let key = value.replace("{{", "").replace("}}", "");
          const newTag = {
            [key]: {
              name: name,
              value: value,
            },
          };
          let copy = { ...templateValue };
          copy = { ...copy, ...newTag };
          setTemplateValue(copy);
          form.resetFields();
        }}
        initialValues={{
          name: "",
          value: "",
        }}
      >
        <Row gutter={16}>
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              name='name'
              label={intl.formatMessage({ id: "justName" })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "required" }),
                },
              ]}
            >
              <Input placeholder='First Name' />
            </Form.Item>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              name='value'
              label={intl.formatMessage({ id: "value" })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "required" }),
                },
                // make sure the value start with {{ and end with }}
                // and the text is not empty
                // and there is no space in the text
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      value.startsWith("{{") &&
                      value.endsWith("}}") &&
                      value.length > 4 &&
                      !value.includes(" ")
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      intl.formatMessage({ id: "invalidTag" })
                    );
                  },
                }),
              ]}
            >
              <Input placeholder='{{first_name}}' />
            </Form.Item>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <Form.Item>
              <CustomButton
                type='primary'
                text={intl.formatMessage({ id: "add" })}
                style={{
                  width: "100%",
                }}
                htmlType='submit'
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        {/* show all tags and make the user able to delete any of them */}
        {Object.keys(templateValue).map((key) => (
          <Row key={key} gutter={16} className='mt-5'>
            <Col md={8} sm={24} xs={24}>
              <Input value={templateValue[key].name} disabled />
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Input value={templateValue[key].value} disabled />
            </Col>
            <Col md={8} sm={24} xs={24}>
              <CustomButton
                // type='danger'
                text={intl.formatMessage({ id: "delete" })}
                style={{
                  width: "100%",
                }}
                danger
                onClick={() => {
                  const copy = { ...templateValue };
                  delete copy[key];
                  setTemplateValue(copy);
                }}
              />
            </Col>
          </Row>
        ))}
      </Form>
    </Modal>
  );
}

export default TagModal;
