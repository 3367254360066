import phoneCods from "../Views/Auth/PhoneCods.json";

export const checkIfValidPhone = (phone) => {
  let isValid = false;
  phone = phone.replace("+", "");
  // console.log(phone)
  if (`${phone[0]}` === "7") {
    if (`${phone.substring(1)}`.length === 9) {
      isValid = true;
    }
  } else if (`${phone[0]}` === "1") {
    if (`${phone.substring(1)}`.length === 10) {
      isValid = true;
    }
  } else {
    let towDigits = phone.substring(0, 2);
    let threeDigits = phone.substring(0, 3);
    let i = phoneCods.findIndex((a) => a.dial_code === `+${towDigits}`);
    if (i !== -1) {
      isValid = true;
    } else {
      if (`${threeDigits}` === `966`) {
        if (`${phone.substring(3)}`.length === 9 && `${phone[3]}` === "5") {
          isValid = true;
        }
      } else {
        isValid = true;
      }
    }
  }
  return isValid;
};
