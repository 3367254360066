import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { message } from "antd";
import Register from "./Views/Auth/index";
import Sidebar from "./Components/Sidebar";
import PrivateRoute from "./Components/PrivateRoute";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Home from "./Views/Home";
import Tracker from "./Views/Tracker";
import Account from "./Views/Account";
import EmailSettings from "./Views/EmailSettings";
import Products from "./Views/Products";
import AdminRoute from "./Components/AdminRoute";
import Stores from "./Views/Admin/Stores";
import OneStoreAdmin from "./Views/Admin/OneStoreAdmin";
import Unsubscribe from "./Views/Unsubscribe";
import Campaign from "./Views/Campaign";
import TestEvents from "./Views/Admin/TestEvents";
import Templates from "./Views/Admin/Templates";
import OneTemplateAdmin from "./Views/Admin/OneTemplateAdmin";
import CustomCampaign from "./Views/CustomCampaign";
import NewCampaign from "./Views/CustomCampaign/components/NewCampaign";
import CustomTemplate from "./Views/CustomTemplate";
import NewTemplate from "./Views/CustomTemplate/components/NewTemplate";
import TemplatesHTML from "./templatess";
import EditTemplate from "./Views/CustomTemplate/components/EditTemplate";
import EditCampaign from "./Views/CustomCampaign/components/EditCampaign";
import Customers from "./Views/Customers";
import NewTemplateAdmin from "./Views/Admin/NewTemplateAdmin";
import EditTemplateAdmin from "./Views/Admin/EditTemplateAdmin";
import { initializeTagManager } from "./API/gtm";
import usePageTracking from "./API/usePageTracking";
import TemplatesLibrary from "./Views/CustomTemplate/components/TemplatesLibrary";
import DefaultTemplates from "./Views/Admin/DefaultTemplates";

// import WhatsappSMS from "./Views/WhatsappSMS";
// import Students from "./Views/Users";
// import SettingWhatsaap from "./Views/SettingWhatsaap";
// import Absence from "./Views/Absence";
// import Latents from "./Views/Latents";
// import Exam from "./Views/Exam";
// import AbsenceReport from "./Views/AbsenceReport";
// import ExamResults from "./Views/ExamResults";
// import AdminSMS from "./Views/AdminSMS";
// import BehavioralReport from "./Views/BehavioralReport";
// import StudentPoints from "./Views/StudentPoints";
// import QRReader from "./Views/QRReader";

const AllRoutes = () => {
  const intl = useIntl();
  const { isOffline } = useSelector((state) => state.settings);
  // App.js

  useEffect(() => {
    initializeTagManager();
  }, []);

  usePageTracking();

  useEffect(() => {
    if (isOffline) {
      message.error(intl.formatMessage({ id: "pleaseCheckInternet" }), 0);
    } else {
      message.destroy();
    }
  }, [isOffline]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Sidebar component={<Home />} />
          </PrivateRoute>
        }
      />

      <Route
        path='/tracker'
        element={
          <PrivateRoute>
            <Sidebar component={<Tracker />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/products'
        element={
          <PrivateRoute>
            <Sidebar component={<Products />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/email-settings'
        element={
          <PrivateRoute>
            <Sidebar component={<EmailSettings />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/account'
        element={
          <PrivateRoute>
            <Sidebar component={<Account />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/campaigns'
        element={
          <PrivateRoute>
            <Sidebar component={<Campaign />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/custom-campaigns'
        element={
          <PrivateRoute>
            <Sidebar component={<CustomCampaign />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/new-campaign'
        element={
          <PrivateRoute>
            <Sidebar component={<NewCampaign />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/templates'
        element={
          <PrivateRoute>
            <Sidebar component={<CustomTemplate />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/new-template'
        element={
          <PrivateRoute>
            <Sidebar component={<NewTemplate />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/templates/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<EditTemplate />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/campaigns/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<EditCampaign />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/customers'
        element={
          <PrivateRoute>
            <Sidebar component={<Customers />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/templatesLibrary'
        element={
          <PrivateRoute>
            <Sidebar component={<TemplatesLibrary />} />
          </PrivateRoute>
        }
      />
      {/* admin routes; */}
      <Route
        path='/stores'
        element={
          <AdminRoute>
            <Sidebar component={<Stores />} />
          </AdminRoute>
        }
      />
      <Route
        path='/stores/:id'
        element={
          <AdminRoute>
            <Sidebar component={<OneStoreAdmin />} />
          </AdminRoute>
        }
      />
      <Route
        path='/default-templates-admin'
        element={
          <AdminRoute>
            <Sidebar component={<DefaultTemplates />} />
          </AdminRoute>
        }
      />
      <Route
        path='/test-events'
        element={
          <AdminRoute>
            <Sidebar component={<TestEvents />} />
          </AdminRoute>
        }
      />
      <Route
        path='/templates-admin'
        element={
          <AdminRoute>
            <Sidebar component={<Templates />} />
          </AdminRoute>
        }
      />
      <Route
        path='/templates-admin/:id'
        element={
          <AdminRoute>
            <Sidebar component={<EditTemplateAdmin />} />
          </AdminRoute>
        }
      />
      <Route
        path='/new-template-admin'
        element={
          <AdminRoute>
            <Sidebar component={<NewTemplateAdmin />} />
          </AdminRoute>
        }
      />
      {/* <Route
        path='/templates/:id'
        element={
          <AdminRoute>
            <Sidebar component={<OneTemplateAdmin />} />
          </AdminRoute>
        }
      /> */}

      <Route path='/unsubscribe/:id' element={<Unsubscribe />} />
      <Route path='*' element={<Register />} />
    </Routes>
  );
};

export default AllRoutes;
