import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  stores: [],
  templates: [],
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStores: (state, action) => {
      state.stores = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
  },
});

export const { setStores: setStoresAction, setTemplates: setTemplatesAction } =
  adminSlice.actions;

export default adminSlice.reducer;
