import { Form, Typography, Select, Input, DatePicker, Row, Col } from "antd";
import React, { useEffect, useRef, useState } from "react";
import COLORS from "../../../Style/colors";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import EmailEditor from "react-email-editor";
import openNotification from "../../../Components/Notifications";
import { createTemplate } from "../../../API/fetch";
import { templateVars } from "./templateVars";
import { useSelector } from "react-redux";
import ImageLibrary from "../../../Components/ImageLibrary";
import { newTemplateDefaultDesign } from "./newTemplateDefaultDesign";

const { Option } = Select;
const { Title, Text } = Typography;
function NewTemplate() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const emailEditorRef = useRef(null);
  const [availableValues, setAvailableValues] = useState(null);
  const [templateValue, setTemplateValue] = useState([]);
  const { products, store } = useSelector((state) => state.store);
  const [showLib, setShowLib] = useState(false);
  const doneRef = useRef(null);
  useEffect(() => {
    const unlayer = emailEditorRef?.current?.editor;
    if (unlayer) {
      // unlayer.init({
      //   projectId: 223654,
      //   displayMode: "email",
      // });
      // // get all merge tags from unlayer;
      // unlayer?.addEventListener("design:updated", (data) => {
      //   console.log("data", data);
      //   const { design, html } = data;
      //   console.log("design", design);
      //   console.log("html", html);
      // });
    }
  }, []);
  useEffect(() => {
    //   // check how many products are available
    if (store?.storeName) {
      let allTags = {
        customer_name: {
          name: templateVars["customer_name"].ar,
          value: `{{customer_name}}`,
          sample: "محمد خالد",
        },
        name: {
          name: "اسم المنتج",
          value: "{{name}}",
        },
        price: {
          name: "سعر المنتج",
          value: "{{price}}",
        },
        product_url: {
          name: "رابط المنتج",
          value: "{{product_url}}",
        },
        product_image: {
          name: "صورة المنتج",
          value: "{{product_image}}",
        },
      };

      let realData = [];
      Object.keys(allTags).forEach((key) => {
        if (key === "customer_name") {
          realData.unshift({
            name: key,
            value: "{{customer_name}}",
          });
        } else {
          realData.push({
            name: key,
            value: allTags[key].sample,
          });
        }
      });
      setTemplateValue(realData);
      setAvailableValues(allTags);
    }
  }, [store, products]);

  const onFinish = async (values) => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      try {
        setLoading(true);
        const { design, html } = data;
        const myData = {
          name: values.name,
          html,
          design,
          variables: templateValue,
        };
        const res = await createTemplate(myData);
        setLoading(false);
        openNotification({
          title: "Success",
          description: "Template created successfully",
          type: "success",
          rtl: true,
        });
        navigate("/templates");
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        openNotification({
          title: "Error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    });
  };

  const getProducts = (ps) => {
    let allTags = {};
    allTags["item_url"] = {
      name: templateVars["item_url"].ar,
      value: `{{item_url}}`,
      sample: ps[0].url,
    };
    allTags["item_image_url"] = {
      name: templateVars["item_image_url"].ar,
      value: `{{item_image_url}}`,
      sample: ps[0].image,
    };
    allTags["item_name"] = {
      name: templateVars["item_name"].ar,
      value: `{{item_name}}`,
      sample: ps[0].name,
    };
    allTags["item_price"] = {
      name: templateVars["item_price"].ar,
      value: `{{item_price}}`,
      sample: ps[0].price,
    };
    console.log("ps2", ps[1]);
    if (ps[1]?.name) {
      allTags["item1_url"] = {
        name: templateVars["item1_url"].ar,
        value: `{{item1_url}}`,
        sample: ps[1].url,
      };
      allTags["item1_image_url"] = {
        name: templateVars["item1_image_url"].ar,
        value: `{{item1_image_url}}`,
        sample: ps[1].image,
      };
      allTags["item1_name"] = {
        name: templateVars["item1_name"].ar,
        value: `{{item1_name}}`,
        sample: ps[1].name,
      };
      allTags["item1_price"] = {
        name: templateVars["item1_price"].ar,
        value: `{{item1_price}}`,
        sample: ps[1].price,
      };
    }
    if (ps[2]?.name) {
      allTags["item2_url"] = {
        name: templateVars["item2_url"].ar,
        value: `{{item2_url}}`,
        sample: ps[2].url,
      };
      allTags["item2_image_url"] = {
        name: templateVars["item2_image_url"].ar,
        value: `{{item2_image_url}}`,
        sample: ps[2].image,
      };
      allTags["item2_name"] = {
        name: templateVars["item2_name"].ar,
        value: `{{item2_name}}`,
        sample: ps[2].name,
      };
      allTags["item2_price"] = {
        name: templateVars["item2_price"].ar,
        value: `{{item2_price}}`,
        sample: ps[2].price,
      };
    }
    return allTags;
  };

  const getTestimonials = (testimonials) => {
    let allTags = {};
    allTags["testimonial_customer"] = {
      name: templateVars["testimonial_customer"].ar,
      value: `{{testimonial_customer}}`,
      sample: testimonials[0].name,
    };
    allTags["testimonial"] = {
      name: templateVars["testimonial"].ar,
      value: `{{testimonial}}`,
      sample: testimonials[0].testimonial,
    };
    if (testimonials[1]?.name) {
      allTags["testimonial2_customer"] = {
        name: templateVars["testimonial2_customer"].ar,
        value: `{{testimonial2_customer}}`,
        sample: testimonials[1].name,
      };
      allTags["testimonial2"] = {
        name: templateVars["testimonial2"].ar,
        value: `{{testimonial2}}`,
        sample: testimonials[1].testimonial,
      };
    }
    if (testimonials[2]?.name) {
      allTags["testimonial3_customer"] = {
        name: templateVars["testimonial3_customer"].ar,
        value: `{{testimonial3_customer}}`,
        sample: testimonials[2].name,
      };
      allTags["testimonial3"] = {
        name: templateVars["testimonial3"].ar,
        value: `{{testimonial3}}`,
        sample: testimonials[2].testimonial,
      };
    }
    return allTags;
  };

  const onLoad = () => {
    emailEditorRef.current.editor.registerCallback(
      "selectImage",
      function (data, done) {
        setShowLib(true);
        doneRef.current = done;
      }
    );
  };

  const onReady = () => {
    // editor is ready
    emailEditorRef.current.editor.loadDesign(newTemplateDefaultDesign);
    emailEditorRef.current.editor.setMergeTags({
      ...availableValues,
    });
    emailEditorRef.current.editor.setSpecialLinks([
      {
        name: "رابط المنتج",
        href: "{{product_url}}",
        target: "_blank",
      },
      {
        name: "رابط السلة",
        href: "{{checkout_url}}",
        target: "_blank",
      },
    ]);
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "newCampaign" })}</Title>
      </div>
      <ImageLibrary
        open={showLib}
        onClose={(url) => {
          if (url && doneRef.current) {
            doneRef.current({ url: url });
          }
          setShowLib(false);
        }}
        selectImage={(url) => {
          console.log("selected", url);
        }}
      />
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className='light-shadow'
      >
        {/* Create a form that has name, type, emails for the campaign */}
        <Row
          gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
          // justify={"space-between"}
          style={
            {
              // backgroundColor: "green",
            }
          }
        >
          <Col md={24} sm={24} xs={24}>
            <Form
              form={form}
              layout='vertical'
              name='new-campaign'
              onFinish={onFinish}
              initialValues={{
                type: "email",
              }}
            >
              <Row gutter={16}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "templateName" })}
                    name='name'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item label={intl.formatMessage({ id: "language" })}>
                    <Select
                      className='my-font'
                      onChange={(e) => {
                        emailEditorRef.current.editor.setLocale(e);
                      }}
                    >
                      <Option value='en-US' className='my-font'>
                        {intl.formatMessage({ id: "english" })}
                      </Option>
                      <Option value='ar-AE' className='my-font'>
                        {intl.formatMessage({ id: "arabic" })}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col
                md={24}
                sm={24}
                xs={24}
                style={{
                  border: "1px solid #f0f0f0",
                  overflow: "hidden",
                }}
              >
                <EmailEditor
                  ref={emailEditorRef}
                  onLoad={onLoad}
                  onReady={onReady}
                  options={{
                    locale: "ar-AE",
                    projectId: 223654,
                  }}
                />
              </Col>
              <Row gutter={16}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item>
                    <CustomButton
                      type='primary'
                      htmlType='submit'
                      loading={loading}
                      text={intl.formatMessage({ id: "create" })}
                      style={{
                        width: "100%",
                        marginTop: 20,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          {/* <Col
            md={1}
            sm={1}
            xs={1}
            style={{
              backgroundColor: COLORS.gray,
              position: "relative",
              top: -20,
            }}
          ></Col> */}
        </Row>
      </div>
    </div>
  );
}

export default NewTemplate;
