import React, { useState } from "react";
import COLORS from "../../Style/colors";
import { Col, Divider, Input, Typography, Row, Form, Select } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import openNotification from "../../Components/Notifications";
import { getRandomData, sendEmailTest } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";

const { Title } = Typography;
const STAGES = {
  WELCOME_CUSTOMER: "welcome-customer",
  VIEW_ITEM: "view_item",
  ADD_TO_CART: "add-to-cart",
  ABANDONED_CART: "aband-cart",
};

const TestEvents = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const getSomeData = async () => {
    try {
      setLoading(true);
      const res = await getRandomData();
      const { stores } = res.data;
      setStores(stores);
      setLoading(false);
      openNotification({
        type: "success",
        title: "Success",
        rtl: true,
        description: "Fetched Successfully",
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      openNotification({
        type: "error",
        title: "Error",
        rtl: true,
        description: "something went wrong",
      });
    }
  };
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {};
      const selected = stores.find((store) => store._id === values.store);
      data.store = selected;
      data.customer = selected.customers.find(
        (customer) => customer._id === values.customer
      );
      data.product = selected.products.find(
        (product) => product._id === values.product
      );
      if (
        values.stage === STAGES.WELCOME_CUSTOMER ||
        values.stage === STAGES.ABANDONED_CART ||
        values.stage === STAGES.ADD_TO_CART
      ) {
        data.products = [data.product, ...selected.products];
      } else {
        data.products = [];
      }
      data.stage = values.stage;

      const res = await sendEmailTest(data);
      openNotification({
        type: "success",
        title: "Success",
        rtl: true,
        description: "Event Sent Successfully",
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      openNotification({
        type: "error",
        title: "Error",
        rtl: true,
        description: "something went wrong",
      });
    }
  };

  const getCustomers = (storeId) => {
    let customers = [];
    if (storeId) {
      customers = stores.find((store) => store._id === storeId)?.customers;
    }
    return customers;
  };
  const getProducts = (storeId) => {
    let products = [];
    if (storeId) {
      products = stores.find((store) => store._id === storeId)?.products;
    }
    return products;
  };
  return (
    <div
      style={{
        backgroundColor: COLORS.white,
        marginTop: 5,
        width: "92%",
        marginRight: "auto",
        marginLeft: "auto",
        borderRadius: 10,
        padding: 15,
      }}
      className='light-shadow'
    >
      <Title level={4}>Test Events</Title>
      <CustomButton
        text={"get some data"}
        onClick={getSomeData}
        loading={loading}
        disabled={loading}
      />
      <Divider />
      <Form
        form={form}
        name='edit-account'
        layout='vertical'
        className='form-container'
        onFinish={onFinish}
        style={{
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        {() => {
          return (
            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name='store'
                  label={intl.formatMessage({ id: "store" })}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select className='my-font'>
                    {stores.map((store) => (
                      <Select.Option key={store._id} value={store._id}>
                        {store.storeName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name='customer'
                  label={intl.formatMessage({ id: "customer" })}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select>
                    {getCustomers(form.getFieldValue("store")).map(
                      (customer) => {
                        return (
                          <Select.Option
                            key={customer._id}
                            value={customer._id}
                          >
                            {customer.email}
                          </Select.Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name='product'
                  label={intl.formatMessage({ id: "product" })}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select>
                    {getProducts(form.getFieldValue("store")).map(
                      (customer) => {
                        return (
                          <Select.Option
                            key={customer._id}
                            value={customer._id}
                          >
                            {customer.name}
                          </Select.Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name='stage'
                  label={intl.formatMessage({ id: "stage" })}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select>
                    {Object.values(STAGES).map((stage) => (
                      <Select.Option key={stage} value={stage}>
                        {stage}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={24} sm={24} xs={24}>
                <CustomButton
                  htmlType='submit'
                  text={"Send Event"}
                  loading={loading}
                  disabled={loading}
                />
              </Col>
            </Row>
          );
        }}
      </Form>
    </div>
  );
};

export default TestEvents;
