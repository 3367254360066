import ABANDED_CART from "./abanedCart.png";
import ABANDED_CART_2 from "./abanededCart2.png";
import DISCOUNT from "./discount.png";
import DISCOUNT_2 from "./discount2.png";
import DISCOUNT_3 from "./discount3.png";
import EID from "./eid.png";
import EID_2 from "./eid2.png";
import FORGET from "./forget.png";
import FORGET_2 from "./forget2.png";
import NEW_PRODUCT from "./newProduct.png";
import NEW_PRODUCTS from "./newProducts.png";
import WELCOME from "./welcome.png";
import WELCOME_2 from "./welcome2.png";

const adminTemplateData = [
  {
    id: "6623c4a0cd0980c8cc01032a",
    image: NEW_PRODUCTS,
  },
  {
    image: DISCOUNT,
    id: "6623c270cd0980c8cc010316",
  },
  {
    image: NEW_PRODUCT,
    id: "6623c06ecd0980c8cc0102d6",
  },
  {
    id: "66227809cd0980c8cc00f935",
    image: WELCOME,
  },
  {
    image: FORGET,
    id: "66225b6bcd0980c8cc00d6d3",
  },
  {
    image: FORGET_2,
    id: "66225b5fcd0980c8cc00d6d0",
  },
  {
    id: "662247edcd0980c8cc00d5da",
    image: EID,
  },
  {
    id: "6621f9abcd0980c8cc00d3e5",
    image: EID_2,
  },
  {
    id: "6621f803cd0980c8cc00d3ca",
    image: WELCOME_2,
  },
  {
    id: "66217d16cd0980c8cc00d038",
    image: DISCOUNT_2,
  },
  {
    id: "6621797dcd0980c8cc00d00a",
    image: DISCOUNT_3,
  },
  {
    id: "661e5a982afa15876ced7ef5",
    image: ABANDED_CART,
  },
  {
    id: "661e56ab2afa15876ced7ebe",
    image: ABANDED_CART_2,
  },
];

export { adminTemplateData };
