import { Image, Spin, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import COLORS from "../../Style/colors";
import { getProductAnalytics } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setAnalyticProductsAction } from "../../redux/reducers/storeReducer";
import dayjs from "dayjs";
import MyTimePicker from "../Home/Components/MyTimePicker";

const { Title } = Typography;
function Products() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { analyticsProducts } = useSelector((state) => state.store);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (analyticsProducts?.length < 1) {
      getDataForTime({
        start: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        end: dayjs().format("YYYY-MM-DD"),
      });
    } else {
      formatEvents();
    }
  }, []);
  const getDataForTime = async ({ start, end }) => {
    try {
      setLoading(true);
      let s = dayjs(start).format("YYYY-MM-DD");
      let e = dayjs(end).format("YYYY-MM-DD");
      const res = await getProductAnalytics(s, e);
      dispatch(setAnalyticProductsAction(res.data.analyticsProducts));
      await new Promise((resolve) => setTimeout(resolve, 800));
      formatEvents(res.data.analyticsProducts);
      openNotification({
        title: "Success",
        description: "Fetched Successfully",
        type: "success",
        rtl: true,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFormattedEvents([]);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const formatEvents = (tmpEvents) => {
    let events = [];
    if (tmpEvents) {
      events = tmpEvents;
    } else {
      events = analyticsProducts;
    }
    if (events.length === 0) {
      setFormattedEvents([]);
      setLoading(false);
      return;
    }
    const formatted = events.map((event) => {
      return {
        key: event.productId,
        _id: event.productId,
        product: event.productName,
        productImage: event.productImage,
        productUrl: event.productUrl,
        sentEmails: event.sentEmails,
        numberOfViews: event.numberOfViews,
      };
    });
    setFormattedEvents(formatted);
    setLoading(false);
  };

  const column = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "image" }),
      dataIndex: "productImage",
      key: "productImage",
      render: (text, record, index) => (
        <Image
          src={text}
          style={{ borderRadius: 5, width: 50, height: 50 }}
          preview={true}
          alt={"image"}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: "product" }),
      dataIndex: "product",
      key: "product",
    },
    {
      title: intl.formatMessage({ id: "views" }),
      dataIndex: "numberOfViews",
      key: "numberOfViews",
      sorter: (a, b) => a.numberOfViews - b.numberOfViews,
    },
    {
      title: intl.formatMessage({ id: "sentEmails" }),
      dataIndex: "sentEmails",
      key: "sentEmails",
      sorter: (a, b) => a.sentEmails - b.sentEmails,
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "products" })}
          </Title>
          <MyTimePicker
            initialTime='last30days'
            loading={loading}
            getData={getDataForTime}
          />
        </div>
        <div>
          <Table
            columns={column}
            dataSource={formattedEvents}
            pagination={{ pageSizeOptions: [10, 50, 100, 500, 1000] }}
            className='light-shadow'
            style={{
              backgroundColor: COLORS.white,
              padding: "5px 5px",
              borderRadius: 10,
            }}
            // scroll={{ x: 1000 }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default Products;
