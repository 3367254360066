export const templateVars = {
  store_name: {
    ar: "اسم المتجر",
    en: "Store Name",
  },
  store_logo_url: {
    ar: " شعار المتجر",
    en: "Store Logo ",
  },
  store_url: {
    ar: "رابط المتجر",
    en: "Store URL",
  },
  store_address: {
    ar: "عنوان المتجر",
    en: "Store Address",
  },
  store_description: {
    ar: "وصف المتجر",
    en: "Store Description",
  },
  testimonial_customer: {
    ar: "اسم الشخص الذي قام بالتقييم",
    en: "Name of the person who reviewed",
  },
  testimonial: {
    ar: "التقييم",
    en: "Review",
  },
  testimonial2_customer: {
    ar: "اسم الشخص الذي قام بالتقييم 2",
    en: "Name of the person who reviewed 2",
  },
  testimonial2: {
    ar: "التقييم 2",
    en: "Review 2",
  },
  testimonial3_customer: {
    ar: "اسم الشخص الذي قام بالتقييم 3",
    en: "Name of the person who reviewed 3",
  },
  testimonial3: {
    ar: "التقييم 3",
    en: "Review 3",
  },
  facebook_url: {
    ar: "رابط الفيسبوك",
    en: "Facebook URL",
  },
  twitter_url: {
    ar: "رابط تويتر",
    en: "Twitter URL",
  },
  instagram_url: {
    ar: "رابط انستغرام",
    en: "Instagram URL",
  },
  whatsapp_url: {
    ar: "رابط واتساب",
    en: "Whatsapp URL",
  },
  tiktok_url: {
    ar: "رابط تيك توك",
    en: "Tiktok URL",
  },
  snapchat_url: {
    ar: "رابط سناب شات",
    en: "Snapchat URL",
  },
  customer_name: {
    ar: "اسم العميل",
    en: "Customer Name",
  },
  item_url: {
    ar: "رابط المنتج ١",
    en: "Item URL 1",
  },
  item_image_url: {
    ar: "صورة المنتج ١",
    en: "Item Image 1",
  },
  item_name: {
    ar: "اسم المنتج ١",
    en: "Item Name 1",
  },
  item_price: {
    ar: "سعر المنتج ١",
    en: "Item Price 1",
  },
  item1_url: {
    ar: "رابط المنتج ٢",
    en: "Item URL 2",
  },
  item1_image_url: {
    ar: "صورة المنتج ٢",
    en: "Item Image 2",
  },
  item1_name: {
    ar: "اسم المنتج ٢",
    en: "Item Name 2",
  },
  item1_price: {
    ar: "سعر المنتج ٢",
    en: "Item Price 2",
  },
  item2_url: {
    ar: "رابط المنتج ٣",
    en: "Item URL 3",
  },
  item2_image_url: {
    ar: "صورة المنتج ٣",
    en: "Item Image 3",
  },
  item2_name: {
    ar: "اسم المنتج ٣",
    en: "Item Name 3",
  },
  item2_price: {
    ar: "سعر المنتج ٣",
    en: "Item Price 3",
  },
  category_name: {
    ar: "اسم التصنيف",
    en: "Category Name",
  },
  category2_name: {
    ar: "اسم التصنيف ٢",
    en: "Category Name 2",
  },
  product_name: {
    ar: "اسم المنتج",
    en: "Product Name",
  },
  product_price: {
    ar: "سعر المنتج",
    en: "Product Price",
  },
};
