import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Row, Col, Spin } from "antd";
import COLORS from "../../Style/colors";
import ProductAnalytics from "./Components/ProductAnalytics";
import openNotification from "../../Components/Notifications";
import {
  getEmailAnalytics,
  getAllProducts,
  getAllMedias,
} from "../../API/fetch";
import dayjs from "dayjs";
import {
  setMediasAction,
  setProductsAction,
} from "../../redux/reducers/storeReducer";
const { Title } = Typography;
function Home() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { events } = useSelector((state) => state.store);

  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState([]);

  useEffect(() => {
    let s = dayjs().subtract(7, "day").format("YYYY-MM-DD");
    let e = dayjs().format("YYYY-MM-DD");
    // getDataEmails(s, e);
    getProducts();
  }, []);

  const getDataEmails = async (start, end) => {
    try {
      setLoading(true);
      const res = await getEmailAnalytics(start, end);
      if (res?.data?.campaigns) {
        let temp = [];
        res.data.campaigns.forEach((campaign) => {
          if (campaign.emailType === null) {
            return;
          }
          let sent = campaign.sent;
          let open = campaign.Open;
          let click = campaign.Click;
          // all clicked emails are opened by default
          if (click > open) open += click;
          temp.push({
            key: campaign.emailType,
            name: intl.formatMessage({ id: campaign.emailType }),
            sent: sent,
            delivery: campaign.Delivery,
            open: open,
            click: campaign.Click,
          });
        });
        setEmailData(temp);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      console.log(err);
    }
  };

  const getProducts = async () => {
    try {
      const res = await getAllProducts();
      const res2 = await getAllMedias();
      if (res.data) {
        dispatch(setProductsAction(res.data.products));
      }
      if (res2.data) {
        dispatch(setMediasAction(res2.data.medias));
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title>{intl.formatMessage({ id: "home" })}</Title>
      </div>
      <div
        style={{
          marginTop: 10,
          width: "100%",
          minHeight: "70vh",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <ProductAnalytics
          getDataEmails={getDataEmails}
          loading={loading}
          setLoading={setLoading}
          emailData={emailData}
        />
      </div>
    </div>
  );
}

export default Home;
