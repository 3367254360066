import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import COLORS from "../../Style/colors";
import { Checkbox, Divider, Input, Row, Select, Space, Typography } from "antd";
import CustomButton from "../../Components/CustomButton";
import openNotification from "../../Components/Notifications";
import { unsubscribeCustomer } from "../../API/fetch";

const { Title, Text } = Typography;
const REASONS = [
  "لم أعد أرغب في تلقي الرسائل",
  "كثرة الرسائل لم تعجبني",
  "لم تعجبني الخدمة المقدمة",
  "لم تعجبني المنتجات",
  "لم يعجبني المتجر",
  "أخرى",
];
function Unsubscribe() {
  const params = useParams();
  const intl = useIntl();
  const [reasonsText, setReasonsText] = useState([]);
  const [customerId, setCustomerId] = useState(params?.id);
  const [otherReasonText, setOtherReasonText] = useState("");
  const [loading, setLoading] = useState(false);
  const search = new URLSearchParams(window.location.search);
  const storeName = search.get("store");

  const handleUnsubscribe = async () => {
    try {
      let disabledReason = [];
      if (reasonsText?.length > 0) {
        disabledReason = reasonsText.filter((reason) => reason !== "أخرى");
      }
      if (otherReasonText?.length > 0) {
        disabledReason.push(otherReasonText);
      }
      const data = {
        customerData: {
          status: "unsubscribed",
          disabledReason,
        },
      };
      setLoading(true);
      const res = await unsubscribeCustomer(customerId, data);

      if (res?.data.status === "success") {
        setLoading(false);
        openNotification({
          type: "success",
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "unsubscribeSuccess" }),
          rtl: true,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        backgroundColor: COLORS.gray,
        width: "92%",
        marginRight: "auto",
        marginLeft: "auto",
        borderRadius: 10,
        padding: 15,
        minHeight: "80vh",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "3vh",
        textAlign: "right",
      }}
      className='light-shadow'
    >
      <Title level={3}>{intl.formatMessage({ id: "unsubscribe" })}</Title>
      <Divider />
      <Title level={5} className='my-font'>
        {intl.formatMessage({ id: "unsubscribeReason" })}
      </Title>
      {/* REASONS display cheboxes of reasors */}
      <Space direction='vertical' style={{ width: "100%", marginTop: 10 }}>
        {REASONS.map((reason) => {
          const selected = reasonsText.includes(reason);
          return (
            <Row key={reason}>
              <Checkbox
                onChange={(value) => {
                  if (value.target.checked) {
                    setReasonsText([...reasonsText, reason]);
                  } else {
                    setReasonsText(reasonsText.filter((r) => r !== reason));
                  }
                }}
                checked={selected}
                className='my-font'
                value={reason}
              >
                {reason}
              </Checkbox>
            </Row>
          );
        })}
        {reasonsText?.includes("أخرى") ? (
          <Input.TextArea
            onChange={(e) => {
              setOtherReasonText(e.target.value);
            }}
            className='my-font'
            placeholder={intl.formatMessage({ id: "otherReason" })}
          />
        ) : null}
      </Space>

      <Divider />
      <CustomButton
        text={intl.formatMessage({ id: "unsubscribe" })}
        type='primary'
        onClick={handleUnsubscribe}
        loading={loading}
        disabled={loading}
      />
    </div>
  );
}

export default Unsubscribe;
