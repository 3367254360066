import React, { useState, useEffect } from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import {
  Typography,
  Space,
  Spin,
  Row,
  Col,
  Form,
  DatePicker,
  Divider,
  Table,
} from "antd";
import { useIntl } from "react-intl";
import MyTimePicker from "./MyTimePicker";
import { getProductAnalytics, getTrackAnalytics } from "../../../API/fetch";
import dayjs from "dayjs";
import {
  setAnalyticProductsAction,
  setAnalyticsDataAction,
} from "../../../redux/reducers/storeReducer";
import CustomButton from "../../../Components/CustomButton";
const { Title, Text } = Typography;

const ProductAnalytics = ({
  getDataEmails,
  loading,
  setLoading,
  emailData,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { analyticsData } = useSelector((state) => state.store);
  const [dateRange, setDateRange] = useState(null);

  const [data, setData] = useState({
    labels: [],
    views: [],
    addToCarts: [],
    abandonedCart: [],
    purchase: [],
  });
  useEffect(() => {
    if (analyticsData?.length < 1) {
      getDataForTime({
        start: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        end: dayjs().format("YYYY-MM-DD"),
      });
    } else {
      setData(analyticsData);
    }
  }, []);

  const getDataForTime = async ({ start, end }) => {
    try {
      setLoading(true);
      let s = dayjs(start).format("YYYY-MM-DD");
      let e = dayjs(end).format("YYYY-MM-DD");
      setDateRange({ start: s, end: e });
      getDataEmails(s, e);
      const res = await getTrackAnalytics(s, e);
      if (res.data?.status === "success") {
        let a = {};
        res?.data?.analyticsData?.tracks.forEach(({ stage, allTracks }) => {
          a[stage] = allTracks;
        });
        setData(a);
        dispatch(setAnalyticsDataAction(a));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // analytics for emails type of email => sent, open, clicked,
  const columns = [
    {
      title: intl.formatMessage({ id: "emailType" }),
      dataIndex: "name",
      key: "name",
    },
    // ema
    {
      title: intl.formatMessage({ id: "sent" }),
      dataIndex: "sent",
      key: "sent",
    },
    {
      title: intl.formatMessage({ id: "open" }),
      dataIndex: "open",
      key: "open",
    },
    {
      title: intl.formatMessage({ id: "clicked" }),
      dataIndex: "click",
      key: "click",
    },
    // {
    //   title: intl.formatMessage({ id: "unsubscribed" }),
    //   dataIndex: "unsubscribed",
    //   key: "unsubscribed",
    // },
  ];

  return (
    <div>
      <Form
        form={form}
        onFinish={(values) => {
          let s = dayjs(values.time[0]).format("YYYY-MM-DD");
          let e = dayjs(values.time[1]).format("YYYY-MM-DD");
          getDataForTime({ start: s, end: e });
        }}
        initialValues={{
          time: [dayjs().subtract(7, "day"), dayjs()],
        }}
      >
        <Space direction='horizontal' size={20}>
          <Form.Item name='time'>
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              type='primary'
              text={intl.formatMessage({ id: "search" })}
            />
          </Form.Item>
        </Space>
      </Form>
      <Spin spinning={loading}>
        {/* Create a boxes that represent the data
        we have 4 boxes: bandedCart, newCustomers, bandedBrowser */}
        <Row justify={"space-between"}>
          <Col md={7} sm={23} xs={23}>
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: 10,
                borderRadius: 10,
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <Title level={5}>
                {intl.formatMessage({ id: "bandedCarts" })}
              </Title>
              <Space direction='vertical'>
                <Title level={1}>{data?.["abandonedCart"]?.length}</Title>
                <Text type='secondary'>
                  {dateRange?.start} - {dateRange?.end}
                </Text>
              </Space>
            </div>
          </Col>
          <Col md={7} sm={23} xs={23}>
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: 10,
                borderRadius: 10,
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <Title level={5}>
                {intl.formatMessage({ id: "bandedBrowser" })}
              </Title>
              <Space direction='vertical'>
                <Title level={1}>{data?.["completed"]?.length}</Title>
                <Text type='secondary'>
                  {dateRange?.start} - {dateRange?.end}
                </Text>
              </Space>
            </div>
          </Col>
          <Col md={7} sm={23} xs={23}>
            <div
              style={{
                backgroundColor: COLORS.white,
                padding: 10,
                borderRadius: 10,
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <Title level={5}>
                {intl.formatMessage({ id: "newCustomers" })}
              </Title>
              <Space direction='vertical'>
                <Title level={1}>{data?.["newCustomers"]?.length}</Title>
                <Text type='secondary'>
                  {dateRange?.start} - {dateRange?.end}
                </Text>
              </Space>
            </div>
          </Col>
        </Row>

        <Divider />
        <div
          style={{
            backgroundColor: COLORS.white,
            padding: 10,
            borderRadius: 10,
            boxShadow: "0px 0px 10px 0px #0000001a",
          }}
        >
          <Table columns={columns} dataSource={emailData} />
        </div>
      </Spin>
    </div>
  );
};

export default ProductAnalytics;
