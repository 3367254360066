import React, { useState, useRef, useEffect } from "react";
import { Layout, Image, Typography, Alert } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import LOGO from "../../assets/images/logo_new.png";
import ICON from "../../assets/images/logo_new.png";
import SuperUserSide from "./SuperUserSide";
import buildNumber from "../../buildNumber";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
let SALA_URL = "https://s.salla.sa/apps/install/1005716042?upgrade=1";

const { Header, Content, Footer, Sider } = Layout;
const { Text, Link } = Typography;
const Sidebar = ({ children, component }) => {
  const intl = useIntl();
  const { rtl, isBigScreen } = useSelector((state) => state.settings);
  const { role, user } = useSelector((state) => state.auth);
  const { store } = useSelector((state) => state.store);
  const sideRef = useRef();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sideBarWidth, setSideBarWidth] = useState(200);
  const [doesReachLimit, setDoesReachLimit] = useState(false);
  const [isLessThan10, setIsLessThan10] = useState(false);

  useEffect(() => {
    if (user?.role !== "admin") {
      if (store?.remainingEmails <= 0) {
        setDoesReachLimit(true);
      } else if (store?.remainingEmails <= 10) {
        setIsLessThan10(true);
      }
    }
  }, [store?.remainingEmails, user?.role]);

  useEffect(() => {
    if (sideRef.current) {
      setSideBarWidth(sideRef.current.offsetWidth);
    }
  }, [sideRef?.current?.offsetWidth]);

  useEffect(() => {
    if (isCollapsed) {
      if (isBigScreen) {
        setSideBarWidth(60);
      } else {
        setSideBarWidth(0);
      }
    } else {
      setSideBarWidth(230);
    }
  }, [isCollapsed, isBigScreen]);
  // console.log(waiter)

  const toggleSideBar = () => {
    if (!isCollapsed && !isBigScreen) {
      setIsCollapsed(true);
    }
  };

  return (
    <Layout>
      {doesReachLimit || isLessThan10 ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 91100,
            textAlign: "center",
          }}
        >
          <Alert
            closable
            className='my-font'
            message={intl.formatMessage(
              {
                id: isLessThan10 ? "reachLessThan10" : "reachLimit",
              },
              {
                remaining: store?.remainingEmails,
              }
            )}
            description={
              <>
                <Text className='my-font'>
                  {intl.formatMessage({
                    id: "upgradePlan",
                  })}
                </Text>{" "}
                <Link
                  style={{
                    color: "white",
                    backgroundColor: COLORS.primary,
                    padding: "0px 10px",
                    borderRadius: 5,
                  }}
                  href={SALA_URL}
                  target='_blank'
                >
                  {intl.formatMessage({
                    id: "upgrade",
                  })}
                </Link>
              </>
            }
            type={isLessThan10 ? "warning" : "error"}
          ></Alert>
        </div>
      ) : null}

      <Header
        style={{
          paddingLeft: rtl ? 5 : sideBarWidth,
          paddingRight: rtl ? sideBarWidth : 5,
          position: "absolute",
          width: "100vw",
        }}
      ></Header>
      <Sider
        breakpoint='lg'
        collapsedWidth={isBigScreen ? "60" : "0"}
        className='light-shadow'
        collapsible
        width={sideBarWidth}
        trigger={null}
        collapsed={isCollapsed}
        onBreakpoint={(collapsed) => {
          setIsCollapsed(collapsed);
        }}
        style={{
          zIndex: 99,
        }}
      >
        {sideBarWidth > 100 ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Image
              src={LOGO}
              preview={false}
              width={180}
              height={100}
              style={{
                marginLeft: rtl ? 0 : 10,
                marginRight: rtl ? 10 : 0,
                objectFit: "contain",
              }}
            />
            <Text
              className='my-font'
              style={{ textAlign: "center" }}
            >{`V${buildNumber.buildMajor}.${buildNumber.buildMinor}.${buildNumber.buildRevision}`}</Text>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Image
              src={ICON}
              preview={false}
              width={50}
              style={{ marginLeft: rtl ? 0 : 5, marginRight: rtl ? 5 : 0 }}
            />
            <Text
              className='my-font'
              style={{ textAlign: "center" }}
            >{`V${buildNumber.buildMajor}.${buildNumber.buildMinor}.${buildNumber.buildRevision}`}</Text>
          </div>
        )}
        <SuperUserSide toggleSideBar={toggleSideBar} />
      </Sider>
      <Layout
        className='site-layout'
        style={{
          marginLeft: rtl ? 0 : sideBarWidth,
          marginRight: rtl ? sideBarWidth : 0,
          marginTop: 60,
          minHeight: "95vh",
        }}
      >
        <div className='toggle-bar'>
          {isCollapsed ? (
            rtl ? (
              <MenuFoldOutlined
                style={{ fontSize: 20 }}
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
            ) : (
              <MenuUnfoldOutlined
                style={{ fontSize: 20 }}
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
            )
          ) : rtl ? (
            <MenuUnfoldOutlined
              style={{ fontSize: 20 }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          ) : (
            <MenuFoldOutlined
              style={{ fontSize: 20 }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          )}
        </div>
        <Content
          style={{
            margin: "16px 16px 0",
            overflow: "initial",
          }}
        >
          {component}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Made by Margrow team ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Sidebar;
