import React, { useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { editSetting } from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Input, Row, Switch, Typography } from "antd";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { setStoreAction } from "../../redux/reducers/storeReducer";
import { DefaultEditor } from "react-simple-wysiwyg";

const { Title, Text } = Typography;
function EmailSettings() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const { store } = useSelector((state) => state.store);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        store: {
          // titleOfEmail: values.titleOfEmail,
          emailContent: values.emailContent,
          doesIncludeCoupon: values.doesIncludeCoupon,
          couponText: values.couponText,
        },
      };
      if (values.doesIncludeCoupon === false) {
        data.store.couponText = "";
      }
      const res = await editSetting(store?._id, data);
      if (res.status === 200) {
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "successUpdate" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        dispatch(setStoreAction(res.data.store));
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "something went wrong",
        type: "error",
        rtl,
      });
    }
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "emailSettings" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className='light-shadow'
      >
        <Form
          name='setting-form'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            // titleOfEmail: store?.titleOfEmail,
            emailContent: store?.emailContent,
            doesIncludeCoupon: store?.doesIncludeCoupon,
            couponText: store?.couponText,
          }}
          style={{
            marginRight: 10,
            marginLeft: 10,
          }}
        >
          {() => {
            return (
              <>
                <Row
                  className='mb-5'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Title */}
                  {/* <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      name='titleOfEmail'
                      label={intl.formatMessage({ id: "titleOfEmail" })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: "titleOfEmailRequired",
                          }),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}
                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      name='emailContent'
                      label={intl.formatMessage({ id: "emailContent" })}
                    >
                      <DefaultEditor />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='doesIncludeCoupon'
                      label={intl.formatMessage({ id: "doesIncludeCoupon" })}
                    >
                      <Switch
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "yes",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "no",
                            })}
                          </p>
                        }
                        onChange={(value) => {
                          // if value is false, set couponText to empty string
                          if (!value) {
                            form.setFieldsValue({ couponText: "" });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='couponText'
                      label={intl.formatMessage({ id: "couponText" })}
                      // required only of doesIncludeCoupon is true
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (getFieldValue("doesIncludeCoupon") && !value) {
                              return Promise.reject(
                                intl.formatMessage({
                                  id: "couponTextRequired",
                                })
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify='start' className='mt-5' align='middle'>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "save" })}
                      className={`btnRegister btn-text border-8 px-5 `}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Row>
              </>
            );
          }}
        </Form>
      </div>
    </div>
  );
}

export default EmailSettings;
