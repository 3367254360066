import React, { useRef } from "react";
import { ONE } from "./builder";
import { useSelector } from "react-redux";
import { render } from "react-dom";

import EmailEditor from "react-email-editor";
function TemplatesHTML({ templateJSON }) {
  const { store } = useSelector((state) => state.store);
  console.log("store", store);
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);

      console.log("design", design);
    });
  };
  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    const templateJson = {};
    if (templateJSON) {
      templateJson = templateJSON;
    }
    emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };
  return (
    <div>
      <button onClick={exportHtml}>Export HTML</button>
      <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      {/* {ONE} */}
    </div>
  );
}

export default TemplatesHTML;
