import {persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import settingsReducer from './settingsReducer';
import authReducer from './authReducer';
import storeReducer from './storeReducer';
import adminReducer from './adminReducer';



const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['settings', 'auth', 'store'], 
  transforms: [], // TODO: secure some data?
};
const reducers = persistCombineReducers(persistConfig, {
    settings: settingsReducer,
    auth: authReducer,
    store: storeReducer,
    admin: adminReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logOut') { // check for action type 
    state = {}; // reset state
    storage.removeItem('persist:root');
  }
  return reducers(state, action);
};


export default rootReducer;
