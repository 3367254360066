import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Table, Typography } from "antd";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import { getStores } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setStoresAction } from "../../redux/reducers/adminReducer";
import { IoRefreshOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
function Stores() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stores } = useSelector((state) => state.admin);
  const [loading, setLoading] = useState(false);
  const [formattedStores, setFormattedStores] = useState([]);

  useEffect(() => {
    if (stores?.length < 1) {
      getAdminData();
    } else {
      formatStores();
    }
  }, []);
  const getAdminData = async () => {
    try {
      setLoading(true);
      const res = await getStores();

      dispatch(setStoresAction(res.data.stores));
      await new Promise((resolve) => setTimeout(resolve, 800));
      formatStores(res.data.stores);
      openNotification({
        title: "Success",
        description: "Fetched Successfully",
        type: "success",
        rtl: true,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFormattedStores([]);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const formatStores = (tmpStores) => {
    let allStores = [];
    if (tmpStores) {
      allStores = tmpStores;
    } else {
      allStores = stores;
    }
    if (allStores.length === 0) {
      setFormattedStores([]);
      setLoading(false);
      return;
    }
    const formatted = allStores.map((store) => {
      return {
        key: store._id,
        phone: store?.user?.phone,
        ...store,
      };
    });
    setFormattedStores(formatted);
    setLoading(false);
  };
  console.log(formattedStores);

  const column = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "merchantName",
      key: "merchantName",
    },
    {
      title: intl.formatMessage({ id: "storeName" }),
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      key: "email",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: intl.formatMessage({ id: "monthlyLimitEmails" }),
      dataIndex: "monthlyLimitEmails",
      key: "monthlyLimitEmails",
      sorter: (a, b) => a.monthlyLimitEmails - b.monthlyLimitEmails,
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record) => <Text>{dayjs(text).format("DD-MM-YYYY")}</Text>,
    },
    {
      title: intl.formatMessage({ id: "package" }),
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: intl.formatMessage({ id: "storeProvider" }),
      dataIndex: "storeProvider",
      key: "storeProvider",
      filters: [
        {
          text: "SALLA",
          value: "SALLA",
        },
        {
          text: "ZID",
          value: "ZID",
        },
      ],
      onFilter: (value, record) => record.storeProvider.includes(value),
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "stores" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getAdminData}
          />
        </div>
        <div>
          <Table
            columns={column}
            dataSource={formattedStores}
            pagination={{ pageSizeOptions: [10, 50, 100, 500, 1000] }}
            className='light-shadow'
            // make the row clickable
            rowClassName={"pointer"}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate(`/stores/${record._id}`);
                }, 
              };
            }}
            style={{
              backgroundColor: COLORS.white,
              padding: "5px 5px",
              borderRadius: 10,
            }}
            scroll={{ x: 1200 }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default Stores;
