import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { getStoreTemplate, sendTestTemplateEmail } from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import { Form, Popconfirm, Space, Spin, Table, Typography } from "antd";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { useNavigate } from "react-router-dom";
import { setStoreTemplatesAction } from "../../redux/reducers/storeReducer";

const { Title, Text } = Typography;

function CustomTemplate() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { store, templates } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (store?._id) {
      getAllTemplates();
    }
  }, [store?._id]);

  const getAllTemplates = async () => {
    try {
      setLoading(true);
      const res = await getStoreTemplate();
      if (res?.data?.templates) {
        dispatch(setStoreTemplatesAction(res?.data?.templates));
        setLoading(false);
        // openNotification({
        //   title: intl.formatMessage({ id: "success" }),
        //   type: "success",
        //   description: intl.formatMessage({ id: "successUpdate" }),
        //   rtl,
        // });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  const sendTestEmailTemplate = async (id) => {
    try {
      setLoading(true);
      const res = await sendTestTemplateEmail(id);
      openNotification({
        title: intl.formatMessage({ id: "success" }),
        type: "success",
        description: "Email has been sent",
        rtl,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "templateName" }),
      dataIndex: "name",
      key: "name",
    },

    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <Text>{new Date(text).toDateString()}</Text>,
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      key: "action",
      render: (text, record) => (
        <CustomButton
          text={intl.formatMessage({ id: "edit" })}
          onClick={() => {
            navigate(`/templates/${record._id}`);
          }}
          type='link'
        />
      ),
    },
    {
      title: intl.formatMessage({ id: "test" }),
      key: "test",
      render: (text, record) => (
        <Popconfirm
          title='هل انت متأكد؟'
          description={`سيتم إرسال إيميل بنفس هذا القالب للايميل الخاص بك`}
          onConfirm={() => {
            sendTestEmailTemplate(record._id);
          }}
          onCancel={() => true}
          okText='إرسال'
          cancelText='تراجع'
        >
          <CustomButton
            text={intl.formatMessage({ id: "sentTest" })}
            onClick={() => {
              // navigate(`/templates/${record._id}`);
            }}
            type='link'
          />
        </Popconfirm>
      ),
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "customTemplate" })}</Title>
        <Space direction='horizontal' size='middle'>
          <CustomButton
            text={intl.formatMessage({ id: "addTemplate" })}
            onClick={() => {
              navigate("/new-template");
            }}
            type='text'
          />
          <CustomButton
            text={intl.formatMessage({ id: "templateLibrary" })}
            onClick={() => {
              navigate("/templatesLibrary");
            }}
            type='primary'
          />
        </Space>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Table
            dataSource={templates?.map((t) => {
              return {
                ...t,
                key: t?._id,
              };
            })}
            columns={columns}
          />
        </div>
      </Spin>
    </div>
  );
}

export default CustomTemplate;
