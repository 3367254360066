import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

const usePageTracking = () => {
  const history = useLocation();
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: history.pathname + history.search,
      },
    });
  }, [history]);
};
export default usePageTracking;
