import { Spin, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import COLORS from "../../Style/colors";
import { getEvents, setEmailToCustomer } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import openNotification from "../../Components/Notifications";
import { TAG_STAGES_COLOR } from "../../Types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setEventsAction } from "../../redux/reducers/storeReducer";
import { IoRefreshOutline } from "react-icons/io5";
import dayjs from "dayjs";

const { Title } = Typography;
function Tracker() {
  // get all quetys from url;
  const intl = useIntl();
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.store);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAllEvents();
  }, []);
  const getAllEvents = async () => {
    try {
      setLoading(true);
      const res = await getEvents();
      dispatch(setEventsAction(res.data.events));
      await new Promise((resolve) => setTimeout(resolve, 800));
      formatEvents(res.data.events);
      openNotification({
        title: "Success",
        description: "Fetched Successfully",
        type: "success",
        rtl: true,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFormattedEvents([]);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const formatEvents = (tmpEven) => {
    let allEvents = [];
    if (tmpEven) {
      allEvents = tmpEven;
    } else {
      allEvents = events;
    }
    if (allEvents.length === 0) {
      setFormattedEvents([]);
      setLoading(false);
      return;
    }
    const formatted = allEvents.map((event) => {
      let timeViewProductInSeconds = 0;
      if (event.startTime && event.endTime) {
        timeViewProductInSeconds =
          (new Date(event.endTime).getTime() -
            new Date(event.startTime).getTime()) /
          1000;
      }
      return {
        key: event._id,
        _id: event._id,
        store: event.storeId.storeName,
        product: event.productId.name,
        customer: event.customerId.name,
        customerEmail: event.customerId.email,
        timeViewProductInSeconds: timeViewProductInSeconds?.toFixed(0),
        createdAt: event.createdAt,
        stage: event.stage,
      };
    });
    setFormattedEvents(formatted);
    setLoading(false);
  };

  const sendEmail = async (eventId) => {
    try {
      setLoading(true);
      const event = events.find((event) => event._id === eventId);
      console.log(event);
      if (!event.customerId) {
        openNotification({
          title: "Error",
          description: "Customer Email is not found",
          type: "error",
          rtl: true,
        });
        setLoading(false);
        return;
      }
      const data = {
        customer: event.customerId,
        product: event.productId,
        store: event.storeId,
      };
      const res = await setEmailToCustomer(data);
      if (res.data.success) {
        openNotification({
          title: "Success",
          description: "Email Sent Successfully",
          type: "success",
          rtl: true,
        });
        setLoading(false);
      } else {
        openNotification({
          title: "Error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const column = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
    },
    {
      title: "Time View Product",
      dataIndex: "timeViewProductInSeconds",
      key: "timeViewProductInSeconds",
      sorter: (a, b) => a.timeViewProductInSeconds - b.timeViewProductInSeconds,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      render: (text, record) => (
        <Tag color={TAG_STAGES_COLOR[text]}>
          {intl.formatMessage({ id: text ? text : "N/A" })}
        </Tag>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => dayjs(text).format("DD/MM/YYYY HH:mm A"),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <CustomButton
          text='Send Email'
          type='primary'
          onClick={() => {
            sendEmail(record._id);
          }}
        />
      ),
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "events" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getAllEvents}
          />
        </div>
        <div>
          <Table
            columns={column}
            dataSource={formattedEvents}
            pagination={{ pageSizeOptions: [10, 50, 100, 500, 1000] }}
            className='light-shadow'
            style={{
              backgroundColor: COLORS.white,
              padding: "5px 5px",
              borderRadius: 10,
            }}
            scroll={{ x: 1000 }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default Tracker;
