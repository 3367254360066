import { Button } from "antd";
import React from "react";

function CustomButton({
  text,
  onClick,
  loading,
  disabled,
  className,
  ...props
}) {
  return (
    <Button
      {...props}
      onClick={onClick}
      disabled={disabled}
      className={`${className} ${"my-font"}`}
      loading={loading}
    >
      {text}
    </Button>
  );
}

export default CustomButton;
