import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  store: null,
  events: [],
  analyticsData: [],
  // analyticsProducts: [],
  templates: [],
  campaigns: [],
  customers: [],
  groups: [],
  products: [],
  medias: [],
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStore: (state, action) => {
      state.store = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setAnalyticsData: (state, action) => {
      state.analyticsData = action.payload;
    },
    setAnalyticProducts: (state, action) => {
      // state.analyticsProducts = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setStoreTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setStoreCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setStoreCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setStoreGroups: (state, action) => {
      state.groups = action.payload;
    },
    setMedias: (state, action) => {
      state.medias = action.payload;
    },
  },
});

export const {
  setStore: setStoreAction,
  setEvents: setEventsAction,
  setAnalyticsData: setAnalyticsDataAction,
  setAnalyticProducts: setAnalyticProductsAction,
  setStoreTemplates: setStoreTemplatesAction,
  setStoreCampaigns: setStoreCampaignsAction,
  setStoreCustomers: setStoreCustomersAction,
  setStoreGroups: setStoreGroupsAction,
  setProducts: setProductsAction,
  setMedias: setMediasAction,
} = storeSlice.actions;

export default storeSlice.reducer;
