import React, { useState,  } from "react";
import { DatePicker, Typography, Select,  } from "antd";
import { useIntl } from "react-intl";
import { getStartEndDate } from "../../../helpers/date";
import dayjs from "dayjs";

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const allOptions = [
  {
    value: "today",
    label: "today",
  },
  {
    value: "yesterday",
    label: "yesterday",
  },
  {
    value: "last7days",
    label: "last7days",
  },
  {
    value: "last14days",
    label: "last14days",
  },
  {
    value: "last30days",
    label: "last30days",
  },
  {
    value: "thisMonth",
    label: "thisMonth",
  },
  {
    value: "lastMonth",
    label: "lastMonth",
  },
  {
    value: "customTime",
    label: "customTime",
  },
];

const MyTimePicker = ({ loading, getData, initialTime='last14days' }) => {
  const intl = useIntl();
  const [selectedTime, setSelectedTime] = useState(initialTime);

  const handleChange = (value) => {
    if (value === "customTime") {
      setSelectedTime(value);
    } else {
      const { start, end } = getStartEndDate(value);
      getData({ start, end });
      setSelectedTime(value);
    }
  };

  const onChange = (dates, dateStrings) => {
    const start = dateStrings[0];
    const end = dateStrings[1];
    getData({ start, end });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Text className='my-font'>{intl.formatMessage({ id: "period" })}</Text>
      <Select
        size={"small"}
        value={selectedTime}
        onChange={handleChange}
        style={{ width: 200 }}
        className='my-font mb-2'
        disabled={loading}
      >
        {allOptions.map(({ value, label }) => {
          return (
            <Option className='my-font' key={value} value={value}>
              {intl.formatMessage({ id: label })}
            </Option>
          );
        })}
      </Select>
      {selectedTime === "customTime" && (
        <RangePicker
          ranges={{
            [intl.formatMessage({ id: "lastMonth" })]: [
              dayjs().subtract(1, "month").startOf("month"),
              dayjs().subtract(1, "month").endOf("month"),
            ],
            [intl.formatMessage({ id: "thisMonth" })]: [
              dayjs().startOf("month"),
              dayjs().endOf("month"),
            ],
            [intl.formatMessage({ id: "last30days" })]: [
              dayjs().subtract(30, "days"),
              dayjs(),
            ],
            [intl.formatMessage({ id: "last14days" })]: [
              dayjs().subtract(14, "days"),
              dayjs(),
            ],
            [intl.formatMessage({ id: "last7days" })]: [
              dayjs().subtract(7, "days"),
              dayjs(),
            ],
            [intl.formatMessage({ id: "yesterday" })]: [
              dayjs().subtract(1, "days"),
              dayjs().subtract(1, "days"),
            ],
            [intl.formatMessage({ id: "today" })]: [dayjs(), dayjs()],
          }}
          onChange={onChange}
          renderExtraFooter={() => "extra footer"}
          dateRender={(current) => {
            const style = {};
            if (current.date() === 1) {
              style.border = "1px solid #1890ff";
              style.borderRadius = "50%";
            }
            return (
              <div className='ant-picker-cell-inner' style={style}>
                {current.date()}
              </div>
            );
          }}
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
        />
      )}
    </div>
  );
};

export default MyTimePicker;
