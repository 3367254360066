import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import {
  getAllAdminTemplates,
  getTemplates,
  updateDefaultTemplate,
} from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Input, Radio, Select, Typography } from "antd";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { setTemplatesAction } from "../../redux/reducers/adminReducer";

const { Title, Text } = Typography;
const CAMPAIGNS = [
  "welcome-customer",
  "welcome-customer-review",
  "view_item",
  "view_item_review",
  "add-to-cart",
  "aband-cart",
];
const PAID_TEXT = ["free", "basic", "premium", "enterprise"];
function DefaultTemplates() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("free");
  const { templates } = useSelector((state) => state.admin);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCampaignObject, setSelectedCampaignObject] = useState(null);
  const [adminTemplates, setAdminTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [subject, setSubject] = useState("");

  useEffect(() => {
    if (templates?.length < 1) {
      getAdminData();
    }
    getAdminTemplates();
  }, []);
  const getAdminData = async () => {
    try {
      setLoading(true);
      const res = await getTemplates();
      dispatch(setTemplatesAction(res.data.templates));
      openNotification({
        title: "Success",
        description: "Fetched Successfully",
        type: "success",
        rtl: true,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };
  const getAdminTemplates = async () => {
    try {
      setLoading(true);
      const res = await getAllAdminTemplates();
      if (res?.data?.adminTemplates) {
        setAdminTemplates(res?.data?.adminTemplates);
      }
      setLoading(false);
      openNotification({
        type: "success",
        title: intl.formatMessage({ id: "success" }),
        description: intl.formatMessage({ id: "savedSuccessfully" }),
        rtl,
      });
    } catch (error) {
      console.log(error);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl,
      });
    } finally {
      setLoading(false);
    }
  };
  const saveDefaultTemplate = async () => {
    try {
      setLoading(true);
      const data = {
        campaignName: selectedCampaign,
        subject,
        selectedPlan,
        selectedTemplate,
      };
      const res = await updateDefaultTemplate(data);
      if (res?.data?.adminTemplates) {
        setAdminTemplates(res?.data?.adminTemplates);
      }
      openNotification({
        type: "success",
        title: intl.formatMessage({ id: "success" }),
        description: intl.formatMessage({ id: "savedSuccessfully" }),
        rtl,
      });
    } catch (error) {
      console.log(error);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>
          {intl.formatMessage({ id: "defaultTemplates" })}
        </Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          minHeight: "70vh",
          marginLeft: "4%",
          marginRight: "4%",
          padding: 10,
          borderRadius: 10,
        }}
        className='light-shadow'
      >
        <div>
          <Title level={5}>{intl.formatMessage({ id: "choosePlan" })}</Title>
          <Radio.Group
            onChange={(e) => {
              setSelectedPlan(e.target.value);
              setSelectedCampaign("");
              setSelectedCampaignObject(null);
              setSelectedTemplate("");
            }}
            value={selectedPlan}
          >
            {PAID_TEXT.map((plan) => (
              <Radio className='my-font' key={plan} value={plan}>
                {intl.formatMessage({ id: plan })}
              </Radio>
            ))}
          </Radio.Group>
          <Divider />
          <Title level={5}>
            {intl.formatMessage({ id: "chooseDefaultTemplate" })}
          </Title>
          <Radio.Group
            onChange={(e) => {
              setSelectedCampaign(e.target.value);
              let obj = adminTemplates.find(
                (camp) => camp.campaignName === e.target.value
              );
              setSelectedCampaignObject(obj);
              setSelectedTemplate(obj?.defaultTemplate?.[selectedPlan]);
              setSubject(obj?.subjects?.[selectedPlan] ?? "");
            }}
            value={selectedCampaign}
          >
            {adminTemplates.map((camp) => (
              <Radio
                className='my-font'
                key={camp?._id}
                value={camp?.campaignName}
              >
                {intl.formatMessage({ id: `${camp?.campaignName}` })}
              </Radio>
            ))}
          </Radio.Group>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <Title level={5}>{intl.formatMessage({ id: "template" })}</Title>
            <Select
              className='my-font'
              value={selectedTemplate}
              onChange={(value) => setSelectedTemplate(value)}
              style={{
                minWidth: 300,
              }}
            >
              <Select.Option
                className='my-font'
                value={selectedCampaignObject?.campaignName}
              >
                {intl.formatMessage({
                  id: "defaultTemplate",
                })}
              </Select.Option>
              {templates?.map((temp) => {
                return (
                  <Select.Option
                    key={temp._id}
                    value={temp._id}
                    className='my-font'
                  >
                    {temp.name}
                  </Select.Option>
                );
              })}
            </Select>
            <div style={{ minHeight: 20 }} />
            <Text
              className='my-font'
              style={{
                fontSize: 15,
              }}
            >
              <span style={{ color: "red" }}>*</span>{" "}
              {intl.formatMessage({ id: "emailTitle" })}
            </Text>
            <Input
              style={{
                marginTop: 10,
              }}
              placeholder={intl.formatMessage({ id: "subject" })}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <div style={{ minHeight: 50 }} />

            <CustomButton
              text={intl.formatMessage({ id: "save" })}
              type='primary'
              loading={loading}
              onClick={saveDefaultTemplate}
              disabled={
                !selectedTemplate ||
                !selectedCampaign ||
                !selectedPlan ||
                (selectedTemplate !== selectedCampaignObject?.campaignName &&
                  subject === "")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultTemplates;
