import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import {
  editSetting,
  getCampaignsDetails,
  sendTestEmail,
} from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { setStoreAction } from "../../redux/reducers/storeReducer";
import { DefaultEditor } from "react-simple-wysiwyg";

const { Title, Text } = Typography;
const CAMPAIGNS = [
  "welcomeEmailActive",
  "mostViewedProductEmailActive",
  "addedToCartEmailActive",
  "abandonedCartEmailActive",
];
const CAMPAIGNS_CONVERTED = {
  welcomeEmailActive: "welcomeCustomer",
  mostViewedProductEmailActive: "completed",
  addedToCartEmailActive: "addedToCart",
  abandonedCartEmailActive: "abandonedCart",
};
const CAMPAIGNS_OPTIONS = {
  welcomeEmailActive: "welcomeEmail",
  mostViewedProductEmailActive: "mostViewedProductEmail",
  addedToCartEmailActive: "addedToCartEmail",
  abandonedCartEmailActive: "abandonedCartEmail",
};
const PAID_TEXT = ["basic", "premium", "enterprise"];
function Campaign() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const { store, templates } = useSelector((state) => state.store);

  let isPaid = PAID_TEXT.includes(store?.subscriptions?.[0]?.plan);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignData, setCampaignData] = useState({});
  const [defaultCampaignOptions, setDefaultCampaignOptions] = useState(null);

  useEffect(() => {
    if (store?._id) {
      getCampaigns();
    }
  }, [store?._id]);

  const getCampaigns = async () => {
    try {
      setLoading(true);
      const res = await getCampaignsDetails();
      setDefaultCampaignOptions(res.data?.store?.defaultCampaignOptions);
      setCampaigns(res.data.campaigns);
      const {
        welcomeEmailActive,
        mostViewedProductEmailActive,
        abandonedCartEmailActive,
        addedToCartEmailActive,
      } = res.data.store;
      setCampaignData({
        welcomeEmailActive,
        mostViewedProductEmailActive,
        abandonedCartEmailActive,
        addedToCartEmailActive,
      });
      form.setFieldsValue({
        welcomeEmailActive,
        mostViewedProductEmailActive,
        abandonedCartEmailActive,
        addedToCartEmailActive,
        welcomeEmail_Subject:
          res?.data?.store?.defaultCampaignOptions?.welcomeEmail?.subject ===
          "DEFAULT"
            ? ""
            : res?.data?.store?.defaultCampaignOptions?.welcomeEmail?.subject,
        welcomeEmail_TemplateId:
          res?.data?.store?.defaultCampaignOptions?.welcomeEmail?.templateId,
        mostViewedProductEmail_Subject:
          res?.data?.store?.defaultCampaignOptions?.mostViewedProductEmail
            ?.subject === "DEFAULT"
            ? ""
            : res?.data?.store?.defaultCampaignOptions?.mostViewedProductEmail
                ?.subject,
        mostViewedProductEmail_TemplateId:
          res?.data?.store?.defaultCampaignOptions?.mostViewedProductEmail
            ?.templateId,
        abandonedCartEmail_Subject:
          res?.data?.store?.defaultCampaignOptions?.abandonedCartEmail
            ?.subject === "DEFAULT"
            ? ""
            : res?.data?.store?.defaultCampaignOptions?.abandonedCartEmail
                ?.subject,
        abandonedCartEmail_TemplateId:
          res?.data?.store?.defaultCampaignOptions?.abandonedCartEmail
            ?.templateId,
        addedToCartEmail_Subject:
          res?.data?.store?.defaultCampaignOptions?.addedToCartEmail
            ?.subject === "DEFAULT"
            ? ""
            : res?.data?.store?.defaultCampaignOptions?.addedToCartEmail
                ?.subject,
        addedToCartEmail_TemplateId:
          res?.data?.store?.defaultCampaignOptions?.addedToCartEmail
            ?.templateId,
        senderEmail:
          store?.senderEmail?.email && store?.senderEmail?.status === "verified"
            ? store?.senderEmail?.email?.replace(
                `@${getFormattedDomain(store?.domain)}`,
                ""
              )
            : "",
      });
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "success" }),
        type: "success",
        description: intl.formatMessage({ id: "successUpdate" }),
        rtl,
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let defaultCampaignOptions = {
        welcomeEmail: {
          subject: values.welcomeEmail_Subject
            ? values.welcomeEmail_Subject
            : "DEFAULT",
          templateId: values.welcomeEmail_TemplateId
            ? values.welcomeEmail_TemplateId
            : "DEFAULT",
        },
        mostViewedProductEmail: {
          subject: values.mostViewedProductEmail_Subject
            ? values.mostViewedProductEmail_Subject
            : "DEFAULT",
          templateId: values.mostViewedProductEmail_TemplateId
            ? values.mostViewedProductEmail_TemplateId
            : "DEFAULT",
        },
        abandonedCartEmail: {
          subject: values.abandonedCartEmail_Subject
            ? values.abandonedCartEmail_Subject
            : "DEFAULT",
          templateId: values.abandonedCartEmail_TemplateId
            ? values.abandonedCartEmail_TemplateId
            : "DEFAULT",
        },
        addedToCartEmail: {
          subject: values.addedToCartEmail_Subject
            ? values.addedToCartEmail_Subject
            : "DEFAULT",
          templateId: values.addedToCartEmail_TemplateId
            ? values.addedToCartEmail_TemplateId
            : "DEFAULT",
        },
      };
      let senderEmail = {};
      if (store?.senderEmail?.status === "verified") {
        senderEmail = {
          email: `${values.senderEmail}@${getFormattedDomain(store?.domain)}`,
          status: "verified",
        };
      }
      const data = {
        store: {
          ...values,
          defaultCampaignOptions,
          senderEmail,
        },
      };

      const res = await editSetting(store?._id, data);
      if (res.status === 200) {
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "successUpdate" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        dispatch(setStoreAction(res.data.store));
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "something went wrong",
        type: "error",
        rtl,
      });
    }
  };

  const getFormattedDomain = (domain) => {
    let tempDomain = domain;
    let formattedEmail = domain;
    if (domain.includes("https://")) {
      tempDomain = domain.split("https://")[1];
    }
    if (domain.includes("http://")) {
      tempDomain = domain.split("http://")[1];
    }
    // remove any thing after domain host;
    if (tempDomain.includes("/")) {
      tempDomain = tempDomain.split("/")[0];
    }
    formattedEmail = tempDomain;
    return formattedEmail;
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "autoCampaigns" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className='light-shadow'
      >
        <Form
          name='setting-form'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            welcomeEmailActive: campaignData.welcomeEmailActive,
            mostViewedProductEmailActive:
              campaignData.mostViewedProductEmailActive,
            abandonedCartEmailActive: campaignData.abandonedCartEmailActive,
            addedToCartEmailActive: campaignData.addedToCartEmailActive,
            welcomeEmail_Subject: defaultCampaignOptions?.welcomeEmail?.subject,
            welcomeEmail_TemplateId:
              defaultCampaignOptions?.welcomeEmail?.templateId,
            mostViewedProductEmail_Subject:
              defaultCampaignOptions?.mostViewedProductEmail?.subject,
            mostViewedProductEmail_TemplateId:
              defaultCampaignOptions?.mostViewedProductEmail?.templateId,
            abandonedCartEmail_Subject:
              defaultCampaignOptions?.abandonedCartEmail?.subject,
            abandonedCartEmail_TemplateId:
              defaultCampaignOptions?.abandonedCartEmail?.templateId,
            addedToCartEmail_Subject:
              defaultCampaignOptions?.addedToCartEmail?.subject,
            addedToCartEmail_TemplateId:
              defaultCampaignOptions?.addedToCartEmail?.templateId,
            senderEmail:
              store?.senderEmail?.email &&
              store?.senderEmail?.status === "verified"
                ? store?.senderEmail?.email?.replace(
                    `@${getFormattedDomain(store?.domain)}`,
                    ""
                  )
                : "",
          }}
          style={{
            marginRight: 10,
            marginLeft: 10,
          }}
        >
          {() => {
            return (
              <>
                <Row
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                  style={{
                    backgroundColor: COLORS.lightGray,
                    padding: "10px 0px 1px",
                    alignItems: "center",
                    borderRadius: 10,
                    marginBottom: 10,
                  }}
                >
                  <Col md={4} sm={24} xs={24}>
                    <Title level={4}>
                      {intl.formatMessage({ id: "campaign" })}
                    </Title>
                  </Col>
                  <Col md={4} sm={24} xs={24}>
                    <Title level={4}>
                      {intl.formatMessage({ id: "status" })}
                    </Title>
                  </Col>
                  <Col md={4} sm={24} xs={24}>
                    <Title level={4}>
                      {intl.formatMessage({ id: "sentEmails" })}
                    </Title>
                  </Col>
                  <Col md={4} sm={24} xs={24}>
                    <Title level={4}>
                      {intl.formatMessage({ id: "template" })}
                    </Title>
                  </Col>
                  <Col md={6} sm={24} xs={24}>
                    <Title level={4}>
                      {intl.formatMessage({ id: "title" })}
                    </Title>
                  </Col>
                </Row>
                {CAMPAIGNS.map((campaign, index) => {
                  return (
                    <Row
                      key={campaign}
                      gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                    >
                      <Col md={4} sm={24} xs={24}>
                        <Text className='my-font'>
                          {intl.formatMessage({ id: `${campaign}_text` })}
                        </Text>
                      </Col>
                      <Col md={4} sm={24} xs={24}>
                        <Form.Item
                          name={campaign}
                          // label={intl.formatMessage({ id: campaign })}
                        >
                          <Switch
                            checkedChildren={
                              <p>
                                {intl.formatMessage({
                                  id: "active",
                                })}
                              </p>
                            }
                            unCheckedChildren={
                              <p>
                                {intl.formatMessage({
                                  id: "disabled",
                                })}
                              </p>
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col md={4} sm={24} xs={24}>
                        <Text className='my-font'>
                          {campaigns[CAMPAIGNS_CONVERTED[campaign]]?.length ||
                            0}
                        </Text>
                      </Col>
                      <Col md={4} sm={24} xs={24}>
                        <Form.Item
                          name={`${CAMPAIGNS_OPTIONS[campaign]}_TemplateId`}
                          label={intl.formatMessage({ id: "template" })}
                        >
                          <Select
                            disabled={isPaid === false}
                            className='my-font'
                            onChange={(value) => {
                              // if the value is default then make the title input value is empty;
                              if (value === "DEFAULT") {
                                form.setFieldsValue({
                                  [`${CAMPAIGNS_OPTIONS[campaign]}_Subject`]:
                                    "",
                                });
                              }
                            }}
                          >
                            <Select.Option value={"DEFAULT"}>
                              {intl.formatMessage({ id: "default" })}
                            </Select.Option>
                            {templates?.map((template) => {
                              return (
                                <Select.Option
                                  key={template._id}
                                  value={template._id}
                                >
                                  {template.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={6} sm={24} xs={24}>
                        <Form.Item
                          name={`${CAMPAIGNS_OPTIONS[campaign]}_Subject`}
                          label={intl.formatMessage({ id: "title" })}
                          // make it required only if the template is not default
                          rules={[
                            {
                              required:
                                form.getFieldValue(
                                  `${CAMPAIGNS_OPTIONS[campaign]}_TemplateId`
                                ) !== "DEFAULT",
                              message: "Required",
                            },
                          ]}
                        >
                          <Input
                            disabled={
                              form.getFieldValue(
                                `${CAMPAIGNS_OPTIONS[campaign]}_TemplateId`
                              ) === "DEFAULT"
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
                {/* senderEmail */}
                <Divider />
                <Form.Item
                  name='senderEmail'
                  label={intl.formatMessage({ id: "senderEmail" })}
                  // required only if the email is verified
                  rules={[
                    {
                      required: store?.senderEmail?.status === "verified",
                      message: "Required",
                    },
                  ]}
                >
                  <Input
                    disabled={store?.senderEmail?.status !== "verified"}
                    addonAfter={
                      rtl ? "" : `@${getFormattedDomain(store?.domain)}`
                    }
                    addonBefore={
                      rtl ? `${getFormattedDomain(store?.domain)}@` : ""
                    }
                  />
                </Form.Item>

                <Row justify='start' className='mt-5' align='middle'>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "save" })}
                      className={`btnRegister btn-text border-8 px-5 `}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Row>
              </>
            );
          }}
        </Form>
      </div>
      <Divider />
      <TestEmails />
    </div>
  );
}

export default Campaign;

const TestEmails = () => {
  const intl = useIntl();
  const { customers, products } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const submitTestEvent = async (values) => {
    try {
      const stageMap = {
        welcomeEmailActive: "welcome-customer",
        mostViewedProductEmailActive: "view_item",
        addedToCartEmailActive: "add-to-cart",
        abandonedCartEmailActive: "aband-cart",
      };
      const data = {
        productId: values.product,
        stage: stageMap[values.stage],
      };
      setLoading(true);
      const res = await sendTestEmail(data);
      openNotification({
        type: "success",
        title: "Success",
        rtl: true,
        description: "Email Sent Successfully",
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      openNotification({
        type: "error",
        title: "Error",
        rtl: true,
        description: "something went wrong",
      });
    }
  };
  return (
    <div
      style={{
        backgroundColor: COLORS.white,
        marginTop: 5,
        width: "92%",
        marginRight: "auto",
        marginLeft: "auto",
        borderRadius: 10,
        padding: 15,
      }}
      className='light-shadow'
    >
      <Title level={4}>{intl.formatMessage({ id: "testEmails" })}</Title>
      <Form
        form={form}
        name='test-emails'
        layout='vertical'
        className='form-container'
        onFinish={submitTestEvent}
        style={{
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        {() => {
          return (
            <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name='product'
                  label={intl.formatMessage({ id: "product" })}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select>
                    {products?.map((p) => {
                      return (
                        <Select.Option key={p._id} value={p._id}>
                          {p.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name='stage'
                  label={intl.formatMessage({ id: "stage" })}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select className='my-font'>
                    {CAMPAIGNS.map((campaign) => (
                      <Select.Option key={campaign} value={campaign}>
                        {intl.formatMessage({ id: `${campaign}_text` })}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={24} sm={24} xs={24}>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "send" })}
                  loading={loading}
                  disabled={loading}
                />
              </Col>
            </Row>
          );
        }}
      </Form>
    </div>
  );
};
