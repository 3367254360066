import React, { useState } from "react";
import { Col, Row, Image, Form, Input, Typography, Select } from "antd";
import { useIntl } from "react-intl";
import LOGO from "../../../assets/images/logo_new.png";
import PlaceHolder from "../../../assets/images/placeHolder.png";
import ICON from "../../../assets/images/logo.png";
import CustomButton from "../../../Components/CustomButton";
import phoneCodes from "../PhoneCods.json";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguageAction } from "../../../redux/reducers/settingsReducer";
import { checkIfValidPhone } from "../../../helpers/index";
import { getRestaurantData, signin } from "../../../API/fetch";
import {
  loginAction,
  setUserHasLevelAction,
} from "../../../redux/reducers/authReducer";
import openNotification from "../../../Components/Notifications/index";

import { useNavigate } from "react-router-dom";
import COLORS from "../../../Style/colors";
import {
  setStoreAction,
  setStoreTemplatesAction,
} from "../../../redux/reducers/storeReducer";
const { Text, Title } = Typography;
const { Option } = Select;

const Login = ({ showSignUp, setShowPinCode, setShowForgetPassword }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const phone = `${966}${values.phone}`;
      const data = {
        credentials: {
          phone,
          password: values.password,
        },
      };
      const res = await signin(data);
      if (res.data.user.role === "admin" || res.data.user.role === "merchant") {
        if (res.data?.store) {
          dispatch(setStoreAction(res.data?.store));
        }
        if (res.data?.templates) {
          dispatch(setStoreTemplatesAction(res.data?.templates));
        }
        dispatch(loginAction(res.data));
        dispatch(setUserHasLevelAction(true));
        if (res.data.user.role === "admin") {
          navigate("/stores");
        } else {
          navigate("/");
        }
        setLoading(false);
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: "ليس لديك الصلاحية",
          type: "error",
          rtl,
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err?.response?.data?.message) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: err.response.data.message,
          type: "error",
          rtl,
        });
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    }
  };
  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={{
          width: 150,
        }}
      >
        {phoneCodes.map((info) => {
          return (
            <Option
              key={`${info.dial_code}-${info.name}`}
              value={info.dial_code}
            >
              {info.dial_code} {info.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <Row style={{ minHeight: "100vh", backgroundColor: COLORS.white }}>
      <Col
        xs={24}
        sm={12}
        type='flex'
        justify='center'
        align='center'
        direction='column'
        className={"authRightSide"}
      >
        <Row type='flex' justify='center' align='middle'>
          <Col span={18}>
            <Row justify='center'>
              <Image
                preview={false}
                width={300}
                src={LOGO}
                className='logo-Login'
              />
            </Row>

            <Form
              name='basic'
              form={form}
              className='form-container'
              layout='vertical'
              onFinish={onFinish}
              initialValues={{
                prefix: phoneCodes[0].dial_code,
              }}
            >
              <Row justify='space-around' align='middle'>
                <Col span={23}>
                  <Form.Item
                    name='phone'
                    label={intl.formatMessage({ id: "phone" })}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorphone" }),
                      },
                      {
                        validator: (_, value) => {
                          const prefix = form.getFieldValue("prefix");
                          if (!prefix) {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                          let valid = checkIfValidPhone(`${prefix}${value}`);
                          if (valid) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      addonBefore={rtl ? null : prefixSelector}
                      addonAfter={rtl ? prefixSelector : null}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={23}>
                  <Form.Item
                    label={intl.formatMessage({ id: "password" })}
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorpassword" }),
                      },
                    ]}
                  >
                    <Input.Password className={"input"} />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify='center' align='middle' className='mt-3'>
                <Col span={24}>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "signin" })}
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col
        xs={0}
        sm={12}
        type='flex'
        className={"authLeftSide"}
        style={{ backgroundColor: COLORS.primary }}
      >
        {/* <Image
          preview={false}
          src={ICON}
          style={{
            maxWidth: 150,
            marginBottom: 50,
            borderRadius: 10,
            backgroundColor: COLORS.white,
          }}
        /> */}
        {/* <Image
          preview={false}
          src={PlaceHolder}
          width={"70%"}
          style={{
            borderRadius: 30,
          }}
        /> */}
      </Col>
    </Row>
  );
};

export default Login;
